<script>

export default {
    name: "CardComponents",
    data() {
        return {}
    },
    props: {
        data: {
            type: Object,
            default: function () {
                return {
                    title: 'title',
                    date: 'null',
                    backgroundImage: require('@/assets/images/01.png')
                }
            },
        },
    },
}
</script>

<template>
    <div class="card">
        <div class="content">
            <div class="overlay">
            </div>
            <div class="title">
                {{ data.title }}
            </div>
            <div class="date">
                {{ data.publishTime }}
            </div>
            <div class="read">
                <div class="read-btn">{{ $t("h.newsCenterProjectDynamics.readMore") }}</div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card {
    position: relative;
    height: 300px;
    width: 100%;
    background-size: cover;
    background: url("@/assets/images/card01.png") no-repeat center;
    transition: all 0.3s ease-in-out;
    user-select: none;
}

.card:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.7);
    transform: translateY(-5px);
}

.content {
    padding: 20px 14px;
    height: 100%;
    width: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.title {
    position: relative;
    color: #ffffff;
    text-align: left;
    font-size: 25px;
    line-height: 40px;
    height: 60%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4; /* 设置为希望的行数 */
    text-overflow: ellipsis;
}

.date {
    display: flex;
    height: 25%;
    align-content: flex-end;
    flex-wrap: wrap;
    position: relative;
    color: #ffffff;
}

.read {
    height: 22%;
    position: relative;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.read-btn {
    height: 60%;
    padding: 0 20px;
    background-color: #4da9c9;
    color: #ffffff;
    font-size: 17px;
    width: fit-content;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 14px;
    cursor: pointer;
    user-select: none;
}

@media (max-width: 767px) {
    .title {
        font-size: 22px;
    }
}

</style>