<template>
  <div class="body">
    <!-- 第一模块 -->
    <div class="gallery-container">
      <div class="photo-section">
        <div v-for="(photoUrl, index) in photos" :key="index" class="photo-frame">
          <el-image :src="photoUrl" alt="Photo" class="photo" fit="cover"/>
        </div>
      </div>
      <div class="project-name-section">
        <div class="project-content">
          <img class="icon1" src="@/assets/images/tb0.png" alt="">

          <div class="project-name">{{ $t("h.DevSupport") }}</div>
        </div>
      </div>
    </div>

    <!-- 第二模块 -->
    <div class="project-overview-container">
      <!-- 上面的盒子：标题 -->
      <div class="title">
        {{ $t("h.projectOverview") }}
    <img class="divider" src="@/assets/images/z0.png" alt="分割线">
  </div>

      <!-- 下面的盒子：分为左右两部分 -->
      <div class="content-container">
        <img alt="" class="left-img" src="@/assets/images/tu7.jpg">
        <!-- 右盒子：文本框 -->
        <p class="text-content">
          {{ $t("h.projectOverviewContent.p1") }}
        </p><br>
        <p class="text-content">
          {{ $t("h.projectOverviewContent.p2") }}
        </p>
      </div>
    </div>

    <!-- 第三模块 -->
    <div class="milestones-container">
        <div class="title">
          {{ $t("h.projectMilestones") }}
        <img class="divider" src="@/assets/images/z0.png" alt="分割线">
    </div>
      <div class="milestones-content">
        <div class="text-box">
          <!-- 这里放置文本内容 -->
          <p class="text-content">
            {{ $t("h.projectMilestonesContent.p1") }}
          </p><br>
          <p class="text-content">
            {{ $t("h.projectMilestonesContent.p2") }}
          </p><br>
          <p class="text-content">
            {{ $t("h.projectMilestonesContent.p3") }}
          </p><br>
          <p class="text-content">
            {{ $t("h.projectMilestonesContent.p4") }}
          </p>
          <div style="margin: 0 auto; text-align: center; padding-top: 2vw;">
            <img alt="" class="text-box-img" src="@/assets/images/card01.png">
          </div>

        </div>
        <!-- <div class="highlighted-number-box"> -->
        <!-- 这里放置突出显示的数字内容 -->
        <!-- <h3>123,456</h3> 示例数字 -->
        <!-- </div> -->
        <!-- <div class="donate-button-container">
            <button class="donate-button">立即捐款</button>
        </div> -->
      </div>
    </div>

        <!-- 第四模块 -->
        <div class="title">{{ $t("h.expectedOutcomes") }}</div>
        <div class="module-four-container">
            <div class="left-side">
                <div class="icon-box">
                    <!-- 图标内容 -->
                    <div class="icon3">
                      <img v-if="isMobile" alt="" class="icon-box-img result" src="@/assets/images/logo-long.png">
                      <img v-else alt="" class="icon-box-img result" src="@/assets/images/logo.png">
                    </div>

                </div>
                <div class="name-box project-name1">
                  {{ $t("h.DevSupport2") }}
                </div>
            </div>
            <div class="right-side">
                <div class="content-split">
                    <div class="content-section" v-for="(item, index) in items" :key="index">
                        <div class="left-icon">
                            <img :src="item.icon" alt="Icon" class="icon-box-img">
                        </div>
                        <div class="middle-arrow">
                            <img class="icon2" src="@/assets/images/tb5.png" alt="">
                        </div>
                        <div class="right-text">
                            <div class="content-section-body">{{ item.text }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

  </div>
</template>

<script>
export default {
    name: 'GalleryComponent',
    data() {
        return {
            photos: [
                require('@/assets/images/tu5.png'),
                require('@/assets/images/tu2.jpg'),
                require('@/assets/images/tu1.png'),
                require('@/assets/images/tu3.jpg'),
                require('@/assets/images/tu4.png'),
                require('@/assets/images/tu6.jpg')
            ],  
            items: [ // 新增的数组，用于存储图标和文本  
                {icon: require('@/assets/images/tb1.png'), text: this.$t("h.expectedOutcomesContent.expectedOutcome1")},
                {icon: require('@/assets/images/tb2.png'), text: this.$t("h.expectedOutcomesContent.expectedOutcome2")},
                {icon: require('@/assets/images/tb3.png'), text: this.$t("h.expectedOutcomesContent.expectedOutcome3")},
                {icon: require('@/assets/images/tb4.png'), text: this.$t("h.expectedOutcomesContent.expectedOutcome4")},
                // 你可以继续添加更多项目  
            ],
        };
    },
};  
</script>

<style scoped>

.body {
  margin-top: 10px;
  background: linear-gradient(to bottom, #ffffff, #f7f8f9);
}

/* 第一模块样式 */
.gallery-container {
  display: flex;
  align-items: stretch;
  padding: 2vw;
  margin: 0 auto;
  width: 85vw;
}

.photo-section {
  width: 70vw; /* 左边部分占70%的视口宽度 */
  display: flex;
  flex-wrap: wrap;
  gap: 1vw;
}

.photo-frame {
    width: calc((100% - 2vw) / 3);; /* 每个相框宽度，减去间隔的一半 */
    height: auto;
    overflow: hidden;

}

.photo {
  width: 100%;
  height: auto;
}

.project-name-section {
  width: 30vw; /* 右边部分占30%的视口宽度 */
  display: flex;
  flex-direction: column;
  /* 垂直布局 */
  justify-content: center;
  align-items: center;
  padding-left: 2vw;
}

.project-content {
  text-align: center;
}

.icon1 {
    font-size: 3.5vw;
    margin-bottom: 2vw;
    width: 22vw;
    height: 15vw;
}
.icon2 {
    width: 3vw;
    height: 1.5vw;
}
.project-name {
    font-size: 2.5vw;
    font-weight: bold;
}


/* 第二模块样式 */
.project-overview-container {
    width: 80%;
    margin: 2vw auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 1vw;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.1); 
    padding: 1vw; 

}

.title {
  font-size: 1.5rem;
  text-align: center;
}


.content-container {
  width: 95%;
}


.content-section-body {
  padding: 10px 0;
}


.left-img {
  width: 30%;
  float: left;
  margin: 1px;
  margin-bottom: 1vw;
  margin-right: 2vw;
}

.right-box {

  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-content {
  text-indent: 2em;
}

.data-display {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon2 {
  margin-bottom: 1vw;
}

.data-amount p,
.data-description p {
  margin: 0;
}

.data-amount {
  font-size: 1.5vw;
  margin-bottom: 0.5vw;
}

.data-description {
  font-size: 1vw;
}

/* 第三模块样式 */
.milestones-container {
    width: 80%;
    margin: 2vw auto;
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 1vw;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.1); 
    padding: 1vw; 
}

.milestones-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.text-box {
  width: 95%;
  text-align: justify;
  margin-bottom: 2vw;
}

.text-box-img {
  width: 50vw;
}

.highlighted-number-box {
  width: 50%;
  padding: 2vw;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-bottom: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highlighted-number-box h3 {
  margin: 0;
  font-size: 2vw;
}

.donate-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.donate-button {
  padding: 1vw 2vw;
  font-size: 1vw;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


/* 第四模块样式 */
.module-four-container {
  display: flex;
  width: 85vw;
  padding: 2vw;
  box-sizing: border-box;
  margin: 0 auto;
  gap: 20px;
}

.left-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: #ffffff;
  padding: 20px 0;
  transition: all 0.3s ease-in-out;
}

.left-side:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.icon-box,
.name-box {
  text-align: center;
  margin-bottom: 1vw;
}

.right-side {
  flex: 2;
  border-radius: 15px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.right-side:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.title-box h2 {
  margin: 0 0 1vw 0;
}

.content-split {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  padding: 10px 0;
}

.content-section {

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  height: 100%;
}

.content-section::after {
  content: '';
  border-bottom: 1px solid #e3e9ed;
  width: 90%;
  margin-left: 5%;
}

.content-section:last-child::after {
  border-bottom: none;
}

.left-icon,
.middle-arrow,
.right-text {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-icon {
  flex: 0 0 20%;
  text-align: center;
}

.middle-arrow {
  flex: 0 0 10%;
}

.right-text {
  flex: 0 0 70%;
  padding: 0 3% 0 1%;
  justify-content: left;
}

.icon3 {
  font-size: 2.5vw;
}

.icon-box-img.result {
  width: 12vw;
  height: auto;
}

.project-name1 {
  font-size: 2vw;
  font-weight: bold;
}

.icon-box-img {
    width: 5vw;
    height: 5vw;
}

/* 响应式布局 */
@media (min-width: 1025px) {

}

/* 平板设备 */
@media (max-width: 1024px) {
  .left-img {
    width: 40%;
  }
}


@media (max-width: 767px) {
  .module-four-container {
    flex-direction: column;
  }


  .icon-box-img.result {
    width: 30vw;
    height: auto;
  }

  .left-img {
    width: 100%;
  }
}

.photo {
  /* 你的图片样式 */
  width: 100%;
  height: 12.5vw;
  /* 或其他你需要的尺寸 */
  /* 保持图片比例 */
}

.divider {
  display: block;
  margin: 1vw auto;
  width: 17%; /* 根据需要调整分割线的宽度 */
  height: auto;
}
</style>