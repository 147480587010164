<script>

import CarouselComponent from "@/components/index/Carousel.vue";
import TabComponent from "@/components/index/Tab.vue";
import TableComponent from "@/components/index/Table.vue";
import PhotoSlider from "@/components/index/PhotoSlider.vue";
import ShowcaseComponent from "@/components/index/Showcase.vue";
import CircleDisplayComponent from "@/components/index/CircleDisplay.vue";
// import PhotoDisplay from "@/components/PhotoDisplay.vue";
// import card from "@/components/Card.vue";

export default {
    name: 'IndexView',
    components: {
        CircleDisplayComponent, ShowcaseComponent, PhotoSlider, TableComponent, TabComponent, CarouselComponent
    },
    data() {
        return {
            bannerImages: [
                {
                    id: 1,
                    image: require("@/assets/images/b1.png"),
                    link: '/#'
                },
                {
                    id: 2,
                    image: require("@/assets/images/b2.png"),
                    link: '/intro'
                },
                {
                    id: 3,
                    image: require("@/assets/images/b3.png"),
                    link: '/AboutUs'
                },
                {
                    id: 4,
                    image: require("@/assets/images/b4.png"),
                    link: '/contact'
                },
                {
                    id: 5,
                    image: require("@/assets/images/b5.png"),
                    link: '/XinWen'
                },
                {
                    id: 6,
                    image: require("@/assets/images/b6.png"),
                    link: '/#'
                },
            ],
            tabs: [
                {name: this.$t("h.Home.projectDynamics"), slotName: 'tab1'},
                // {name: '网站公告', slotName: 'tab2'},
                // {name: '视频动态', slotName: 'tab3'}
            ],
            sliderImages: [
                {
                    id: 1,
                    image: require("@/assets/images/tu1.jpg"),
                },
                {
                    id: 2,
                    image: require("@/assets/images/tu2.jpg"),
                },
                {
                    id: 3,
                    image: require("@/assets/images/tu3.jpg"),
                },
                {
                    id: 4,
                    image: require("@/assets/images/tu4.jpg"),
                },
                {
                    id: 5,
                    image: require("@/assets/images/tu5.jpg"),
                },
                {
                    id: 6,
                    image: require("@/assets/images/tu6.jpg"),
                },
                {
                    id: 7,
                    image: require("@/assets/images/tu7.jpg"),
                },
                {
                    id: 8,
                    image: require("@/assets/images/tu8.jpg"),
                },
                {
                    id: 9,
                    image: require("@/assets/images/tu9.jpg"),
                },
            ],
            /*
            recordItems: [
              {
                id: 1,
                num: 'Users Registered:\n 10,000+',
                image: require('@/assets/images/re1.png'),
                describe: 'Over 10,000 users have registered on our platform, showcasing our growing community.'
              },
              {
                id: 2,
                num: 'Projects Completed: \n500+',
                image: require('@/assets/images/re2.png'),
                describe: 'Successfully completed over 500 projects, demonstrating our commitment to quality and excellence.'
              },
              {
                id: 3,
                num: 'Partnerships Established:\n 50+',
                image: require('@/assets/images/re3.png'),
                describe: 'Established over 50 strategic partnerships with leading companies in the industry.'
              },
              {
                id: 4,
                num: 'Awards Won:\n 20+',
                image: require('@/assets/images/re4.png'),
                describe: 'Received more than 20 prestigious awards for our innovative solutions and outstanding service.'
              },
              */
            photoItems: [
                {
                    id: 1,
                    link: '01.png',
                    to: '/'
                },
                {
                    id: 2,
                    link: 'card01.png',
                    to: '/'
                },
                {
                    id: 3,
                    link: '01.png',
                    to: '/'
                },
                {
                    id: 4,
                    link: 'card01.png',
                    to: '/'
                },
                {
                    id: 5,
                    link: '01.png',
                    to: '/'
                },
            ],
            bannerHeight: this.getBannerHeight(),
            projectActivities: [],
        }
    },
    computed: {
        recordItems() {
            return [
                {
                    id: 1,
                    // num: 'Users Registered:\n 10,000+',
                    num: this.$t("h.Home.ourAchievements.ourAchievements"),
                    image: require('@/assets/images/re1.png'),
                    describe: this.$t("h.Home.ourAchievements.blindChildrenAchievements")
                },
                {
                    id: 2,
                    num: this.$t("h.Home.ourAchievements.donations.title"),
                    image: require('@/assets/images/re2.png'),
                    describe: this.$t("h.Home.ourAchievements.donations.details[0]") + '\n' + this.$t("h.Home.ourAchievements.donations.details[1]")
                },
                {
                    id: 3,
                    num: this.$t("h.Home.ourAchievements.campusUpgrades"),
                    image: require('@/assets/images/re3.png'),
                    describe: this.$t("h.Home.ourAchievements.campusUpgradesDetails[0]") + '\n' + this.$t("h.Home.ourAchievements.campusUpgradesDetails[1]")
                },
                {
                    id: 4,
                    num: this.$t("h.Home.ourAchievements.communityActivities"),
                    image: require('@/assets/images/re4.png'),
                    describe: this.$t("h.Home.ourAchievements.communityActivitiesDetails[0]") + '\n' + this.$t("h.Home.ourAchievements.communityActivitiesDetails[1]")
                },
            ]
        },
        sliceProjectActivities() {
            // 确保 projectActivities 是一个数组，并且长度大于 6 时才切割
            if (this.projectActivities && this.projectActivities.length > 6) {
                return this.projectActivities.slice(0, 6);
            }
            return this.projectActivities;  // 否则返回原始数组
        },
    },
    methods: {
        getProjectActivities() {
            this.$axios({
                method: 'get',
                url: 'api/common/article/list',
                // url: 'http://127.0.0.1:4523/m1/5093316-4755738-default/common/article/list',
                headers: {
                    'Content-Type': "application/json;charset=UTF-8"
                },
            }).then(res => {
                if (res.data.code === 1) {
                    this.projectActivities = res.data.data;
                } else {
                    console.log(res.data.message);
                }
            })
        },
        getBannerHeight() {
            const width = window.innerWidth;
            if (width < 767) {
                this.bannerHeight = '45vw';
            } else {
                this.bannerHeight = '35vw';
            }
        },
        showToast(message, type) {
            console.log(message, type)
        },
        fetchBannerImages() {
            this.$axios({
                method: 'get',
                url: '',
                headers: {
                    'Content-Type': "application/json;charset=UTF-8"
                },
            }).then(res => {
                if (res.data.code === 1) {
                    this.bannerImages = res.data.data;
                } else {
                    console.log(res.data.message)
                }
            })
        },
        fetchSliderImages() {
            this.$axios({
                method: 'get',
                url: '',
                headers: {
                    'Content-Type': "application/json;charset=UTF-8"
                },
            }).then(res => {
                if (res.data.code === 1) {
                    this.projectActivities = res.data.data;
                } else {
                    console.log(res.data.message)
                }
            })
        },

    },
    mounted() {
        this.getProjectActivities();
        this.getBannerHeight();
        window.addEventListener('resize', this.getBannerHeight);
        // this.fetchBannerImages();
        // this.fetchProjectActivities();
        // this.fetchSliderImages();
    },
    destroyed() {
        window.removeEventListener('resize', this.getBannerHeight);
    }
};
</script>

<template>
    <div id="index">
        <div class="container">
            <div class="banner component">
                <!--suppress JSValidateTypes -->
                <CarouselComponent :height="bannerHeight" :image-links="bannerImages" indicator-position="outside"
                                   weight="100%"/>
            </div>
            <div class="tab component">
                <TabComponent :tabs="tabs" :title="this.$t('h.Home.newsCenter')">
                    <div slot="tab1" class="tab-item">
                        <TableComponent :data="sliceProjectActivities">
                        </TableComponent>
                    </div>
                </TabComponent>
            </div>
            <div class="slider component">
                <PhotoSlider :images="sliderImages" :preview-list="sliderImages"
                             :title="$t('h.Home.photoGallery')"></PhotoSlider>
            </div>
            <div class="honer-showcase component">
                <ShowcaseComponent :info="$t('h.Home.ourAchievements.togetherForABrighterFuture')"
                                   :title="this.$t('h.Home.ourAchievements.ourAchievements')">
                    <CircleDisplayComponent :recordItems="recordItems"/>
                </ShowcaseComponent>
            </div>
            <!--      <div class="Certification-showcase component">
                    <ShowcaseComponent info="描述" title="我们的认证">
                      <PhotoDisplay :photoItems="photoItems" />
                    </ShowcaseComponent>
                  </div>-->
        </div>
    </div>
</template>

<style scoped>
.component {
    margin: 70px auto;
}

.container {
    margin: 0 auto;
    width: 85vw;
    background-color: rgba(250, 251, 253, 0.1);
}

.banner {
    width: 85%;
}

.tab {
    height: fit-content;
    width: 100%;
}

.tab-item {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.slider {
    width: 100%;
}

@media (max-width: 767px) {
    .container {
        width: 90vw;
    }

    .banner {
        width: 100%;
    }
}
</style>
