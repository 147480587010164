<script>
export default {
   name: "ShowcaseComponent",
   data() {
      return {};
   },
   props: {
      title: {
         type: String,
         default: undefined,
      },
      info: {
         type: String,
         default: undefined,
      },

   }
}
</script>


<template>
   <div class="showcase">
      <div class="text">
         <div class="title">
            {{ title }}
         </div>
         <div class="info">
            {{ info }}
         </div>
      </div>
      <div class="content">
         <slot></slot>
      </div>
   </div>
</template>


<style scoped>
.showcase {
   width: 100%;
}

.text {
   margin: 20px auto;
}

.title {
   color: #2b2b2b;
   font-size: 30px;
   font-weight: bold;
   height: 50px;
   text-align: center;
}

.info {
   color: #2b2b2b;
   font-size: 14px;
   height: 100px;
   margin: 0 auto;
   text-align: center;
   background-color: rgba(250, 252, 250, 0.3);
   padding-top: 0.8vw;
}

.content {
   width: 100%;
}

</style>