<script>
import CardComponents from "@/components/index/Card.vue";

export default {
    name: 'TableComponent',
    components: {
      CardComponents,
    },
    props:{
      columnWidth:{
        type:Number,
        default:3,
      },
      data:{
        type:Array,
      }
    },
    data() {
      return {
        itemStyle: {

        }
      }
    },
    computed: {
      computedColumnWidth(){
        return this.props.columnWidth;
      },
    },
  }
</script>

<template>
  <div class="table">
    <div v-for="(item,index) in data" :key="index" :style="itemStyle" class="item">
        <!--      { name: 'NewsDetail', params: { id: 1 }}-->
        <router-link :to="{ name:'NewsDetail',params:{id:item.id}}">
            <CardComponents :data="item"></CardComponents>
        </router-link>

    </div>
  </div>
</template>

<style scoped>
  .table{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
    align-items: center;
    gap: 30px;
  }

  .item{
    flex-basis: calc((100% - 60px)/3);
  }

  /*
  响应式布局之媒体查询
 */

  /* 响应式布局 */

  @media only screen and (min-width: 1025px){

  }

  /* 平板设备 */
  @media only screen and  (max-width: 1024px) {
    .item{
      flex-basis: calc((100% - 30px)/2);
    }

  }

  @media only screen and (max-width: 767px) {
    .item{
      flex-basis: calc(100%);
    }
  }


</style>