<template>
    <div class="container">
      <div class="main-box">
        <div class="title-box">视频动态 > 详情页面</div>
        <div class="video-details-box">
          <div class="video-player-box">
            <!-- 视频播放器部分 -->
            <video controls width="100%" height="auto">
              <source src="shipin/4.mp4" type="video/mp4" />
              您的浏览器不支持 HTML5 视频。
            </video>
          </div>
          <div class="video-details-section">
            <!-- 视频详情部分 -->
            <div class="video-title-box">
              <h2 class="video-title">{{ videoTitle }}</h2>
            </div>
            <div class="video-group1">
              <div class="video-details-top">
                <h2>视频详情</h2>
                <p>{{ videoDescription }}</p>
              </div>
            </div>
            <div class="video-group">
              <div class="video-details-top">
                <h2>小标题 1</h2>
                <p>文本</p>
              </div>
              <div class="video-details-top">
                <h2>小标题 2</h2>
                <p>文本</p>
              </div>
            </div>
            <div class="additional-box"></div>
            <div class="video-group">
              <div class="video-details-top">
                <h2>小标题 3</h2>
                <p>文本</p>
              </div>
              <div class="video-details-top">
                <h2>小标题 4</h2>
                <p>文本</p>
              </div>
            </div>
            <div class="additional-box"></div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        videoTitle: '标题',
        videoDescription: '这里是视频的详细描述。可以包括视频的相关信息、作者、发布日期等内容。',
      };
    },
    mounted() {
      const videoId = this.$route.params.id;
      this.fetchVideoDetails(videoId);
    },
    methods: {
      fetchVideoDetails(videoId) {
        const video = this.getVideoById(videoId);
        if (video) {
          this.videoTitle = video.title;
          this.videoDescription = video.description;
        }
      },
      getVideoById(id) {
        const videos = [
          { id: 1, title: '视频 1', description: '视频 1 详情' },
          { id: 2, title: '视频 2', description: '视频 2 详情' },
          { id: 3, title: '视频 3', description: '视频 3 详情' },
          { id: 4, title: '视频 4', description: '视频 4 详情' },
          { id: 5, title: '视频 5', description: '视频 5 详情' },
          { id: 6, title: '视频 6', description: '视频 6 详情' },
          { id: 7, title: '视频 7', description: '视频 7 详情' },
          { id: 8, title: '视频 8', description: '视频 8 详情' }
        ];
        return videos.find(video => video.id === parseInt(id));
      }
    }
  };
  </script>
  

  
  <style scoped>
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
  }
  
  .container {
    max-width: 80%;
    margin: 0 auto;
    padding: 2vw;
}
  
.main-box {
    position: relative;
    background-color: #f0f0f0;
    padding: 1.7vw; 
    border: 0.1vw solid #ddd; 
}
  
.title-box {
    position: absolute;
    top: 1vw; 
    left: 1.7vw; 
    background-color: #ffffff;
    padding: 0.7vw 1vw; 
    font-size: 1.1vw; 
    font-weight: bold;
    color: #333;
    border: 0.1vw solid #ddd; 
}
  
.video-details-box {
    margin-top: 3vw; 
    display: flex;
    flex-wrap: wrap;
    gap: 2vw; 
    padding: 1.8vw;
    background-color: #ffffff;
    border: 0.1vw solid #ddd;
  }
  
  .video-title-box {
    background-color: #ffffff;
    padding: 1.2vw 1.5vw; 
    width: 200px;
    text-align: center;
    border: 0.1vw solid #ddd;
    margin-bottom: 2vw; 
    margin-left: auto;
    margin-right: auto;
  }
  
  .video-title {
    font-size: 1vw; 
    font-weight: bold;
    color: #333;
    margin: 0;
  }
  
  .video-player-box {
    background-color: #f0f0f0;
    padding: 2vw;
    flex: 1 1 100%;
    min-height: 300px;
    border: 0.1vw solid #ddd;
  }
  
  .video-details-section {
    background-color: #f0f0f0;
    padding: 2vw;
    flex: 1 1 100%;
    border: 0.1vw solid #ddd;
    display: flex;
    flex-wrap: wrap;
    gap: 1vw;
  }
  
  .video-group1 {
    display: flex;
    flex-direction: column;
    gap: 2vw; 
    width: 100%;
    margin-bottom: 2vw; 
    background-color: #ffffff;
  }
  
  .video-group {
    display: flex;
    flex-direction: column;
    gap: 2vw; 
    width: calc(70% - 2vw); 
    margin-bottom: 2vw; 
    background-color: #f0f0f0;
  }
  
  .video-details-top {
    background-color: #ffffff;
    padding: 2vw;
    border: 0.1vw solid #ddd;
    flex: 1 1 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .video-details-top h2 {
    font-size: 1vw; 
    font-weight: bold;
    color: #333;
    margin-bottom: 1vw; 
  }
  
  .video-details-top p {
    font-size: 0.8vw; 
    color: #666;
    margin-bottom: 0;
  }
  
  .additional-box {
    flex: 1 0 30%;
    min-height: 300px;
    background-color: #ffffff;
    padding: 0.5vw 1vw;
    border: 0.1vw solid #ddd;
    background-image: url('tupian/1.jpg');
    background-size: cover;
    background-position: center;
  }
  
  @media (max-width: 768px) {
    .video-details-box {
      flex-direction: column;
    }
  
    .video-group {
      margin-bottom: 20px;
    }
  }
  </style>
  