<template>
    <div class="body">
        <div class="shell"><!-- 外层容器 -->
            <!-- 注册表单容器 -->
            <div ref="formContainer" :class="{ 'a-container': isLoginForm, 'b-container': !isLoginForm }"
                 class="container">
                <form v-if="isLoginForm" class="form" @submit.prevent="submitForm('login')">
                    <h2 class="form_title title">欢迎登录</h2>
                    <span class="form_span">选择登录方式</span>
                    <input v-model="loginForm.username" class="form_input" placeholder="用户名" type="text">
                    <input v-model="loginForm.password" class="form_input" placeholder="密码" type="password">
                    <input v-model="loginForm.authCode" class="form_input" placeholder="验证码" type="password">
                    <img :src="this.authCode.img" alt="验证码" class="auth-code">
                    <a class="form_link">忘记密码？</a>
                    <button class="form_button button submit">LOG IN</button>
                </form>

                <!-- 注册表单 -->
                <form v-else class="form" @submit.prevent="submitForm('register')">
                    <h2 class="form_title title">账号注册</h2>
                    <span class="form_span">选择注册方式</span>
                    <input v-model="registerForm.username" class="form_input" placeholder="账号" type="text">
                    <input v-model="registerForm.password" class="form_input" placeholder="密码" type="password">
                    <input v-model="confirmPassword" class="form_input" placeholder="确认密码" type="password">
                    <input v-model="registerForm.email" class="form_input" placeholder="邮箱" type="email">

                    <button class="form_button button submit">SIGN UP</button>
                </form>
            </div>

            <!-- 切换登录注册容器 -->
            <div id="switch-cnt" class="switch">
                <div class="switch_circle"></div>
                <div class="switch_circle switch_circle-t"></div>
                <div id="switch-c1" :class="{ 'is-hidden': isLoginForm }" class="switch_container">
                    <h2 class="switch_title title" style="letter-spacing: 0;">Welcome Back！</h2>
                    <p class="switch_description description">已经有账号了，去登录</p>
                    <button class="switch_button button switch-btn" @click="toggleForm">LOG IN</button>
                </div>

                <div id="switch-c2" :class="{ 'is-hidden': !isLoginForm }" class="switch_container">
                    <h2 class="switch_title title" style="letter-spacing: 0;">Hello Friend！</h2>
                    <p class="switch_description description">去注册一个账号，一起踏入心灵的旅途！</p>
                    <button class="switch_button button switch-btn" @click="toggleForm">SIGN UP</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdminLogin',
    data() {
        return {
            isLoginForm: true, // 控制显示登录表单还是注册表单
            loginForm: {
                username: '',
                password: '',
                authCode: '',
            },
            registerForm: {
                username: '',
                password: '',
            },
            confirmPassword: '',
            authCode: {
                img: '',
                key: '',
            },
        };
    },
    methods: {
        // 切换表单的显示状态
        toggleForm() {
            this.isLoginForm = !this.isLoginForm;
            this.loginForm = {},
                this.registerForm = {},
                this.confirmPassword = ''
        },
        // 提交表单
        submitForm(type) {
            if (type === 'login') {
                this.$axios({
                    method: 'post',
                    url: '/api/admin/login',
                    headers: {
                        'Content-Type': "application/json;charset=UTF-8"
                    },
                    data: this.loginForm
                }).then(res => {
                    if (res.data.code === 1) {
                        this.$message({
                            showClose: true,
                            message: '欢迎回来，' + this.loginForm.username + '！',
                            type: 'success'
                        });
                        this.$router.push('/admin');
                    } else {
                        this.$message({
                            showClose: true,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
            } else if (type === 'register') {
                if (this.registerForm.username === '' || this.registerForm.password === '' || this.confirmPassword === '') {
                    this.$notify.error({
                        title: '错误',
                        message: '录入信息不能为空'
                    })
                    return;
                }
                if (this.registerForm.password !== this.confirmPassword) {
                    this.$message({
                        showClose: true,
                        message: '两次密码输入不一致',
                        type: 'error'
                    });
                    return false;
                } else {
                    this.$axios({
                        method: 'post',
                        url: '/api/admin/register',
                        headers: {
                            'Content-Type': "application/json;charset=UTF-8"
                        },
                        data: this.registerForm
                    }).then(res => {
                        if (res.data.code === 1) {
                            this.$message({
                                showClose: true,
                                message: '恭喜你，注册成功',
                                type: 'success'
                            });
                            this.registerForm = {};
                            this.confirmPassword = '';
                            this.isLoginForm = !this.isLoginForm;
                        } else {
                            this.$message({
                                showClose: true,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                }
            }
        },
        getAutoCode() {
            this.$axios({
                method: 'get',
                url: 'https://apifoxmock.com/m1/5093316-4755738-default/auth/code',
                headers: {
                    'Content-Type': "application/json;charset=UTF-8"
                },
            }).then(res => {
                if (res.data.code === 1) {
                    this.autoCode = res.data.data;
                    console.log(this.autoCode)
                } else {
                    console.log(res.data.message);
                }
            })

        },
    },
    mounted() {
        this.getAutoCode();
    }
};
</script>


<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
    /* 禁止用户选中文本 */
}

.body {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /*居中对齐*/
    font-size: 12px;
    /*字体大小*/
    background-color: #ecf0f3;
    color: #a0a5a8;
    background-image: url('https://web-study-junwei.oss-cn-guangzhou.aliyuncs.com/login_bg.jpg');
    background-size: cover;
    /* 背景图片等比例缩放 */
}

.shell {
    position: relative;
    width: 1000px;
    min-width: 1000px;
    min-height: 600px;
    height: 600px;
    padding: 25px;
    background: linear-gradient(to right bottom,
    rgba(255, 255, 255, .7),
    rgba(255, 255, 255, .5),
    rgba(255, 255, 255, .4) /*背面模糊的颜色*/
    );

    box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
    border-radius: 12px;
    overflow: hidden;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 20px #4ab073;
}

/* 根据不同屏幕宽度设置.shell的缩放比例 */
@media (max-width: 1200px) {
    .shell {
        transform: scale(0.7);
    }
}

@media (max-width: 1000px) {
    .shell {
        transform: scale(0.6);
    }
}

@media (max-width: 800px) {
    .shell {
        transform: scale(0.5);
    }
}

@media (max-width: 600px) {
    .shell {
        transform: scale(0.4);
    }
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    /*顶部对齐*/
    width: 600px;
    height: 100%;
    padding: 25px;
    background-color: #fBD7DF;
    /*注册登录的背面颜色*/
    transition: 1.25s;
    /*过渡时长1.25秒*/
}


.form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.iconfont {
    margin: 0 5px;
    border: rgba(0, 0, 0, 0.5) 2px solid;
    border-radius: 50%;
    font-size: 25px;
    padding: 3px;
    opacity: 0.5;
    transition: 0.1s;
}

.iconfont:hover {
    opacity: 1;
    transition: 0.15s;
    cursor: pointer;
}

.form_input {
    width: 350px;
    height: 40px;
    margin: 4px 0;
    padding-left: 25px;
    font-size: 13px;
    letter-spacing: 0.15px;
    border: none;
    outline: none;
    background-color: #ffffff;
    transition: 0.25s ease;
    border-radius: 8px;
    box-shadow: inset 2px 2px 4px #FFE9F1, inset -2px -2px 4px #fFB0CD;
    /*输入框颜色不动*/
}

.form_input:focus {
    box-shadow: inset 4px 4px 4px #d1d9e6, inset -4px -4px 4px #f9f9f9;
}

.form_span {
    margin-top: 30px;
    margin-bottom: 12px;
}

.form_link {
    color: #181818;
    font-size: 15px;
    margin-top: 25px;
    border-bottom: 1px solid #a0a5a8;
    line-height: 2;
}

.title {
    font-size: 34px;
    font-weight: 700;
    line-height: 3;
    color: #181818;
    letter-spacing: 10px;
}

.description {
    font-size: 14px;
    letter-spacing: 0.25px;
    text-align: center;
    line-height: 1.6;
}

.button {
    width: 180px;
    height: 50px;
    border-radius: 25px;
    margin-top: 50px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1.15px;
    background-color: #A3DFE4;
    color: #f9f9f9;
    box-shadow: 8px 8px 16px #ead8e9, -8px -8px 16px #b6ddc4;
    border: none;
    outline: none;
}

.a-container {
    z-index: 100;
    left: calc(100% - 600px);
}

.b-container {
    left: calc(100% - 600px);
    z-index: 0;
}

.switch {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 400px;
    padding: 50px;
    z-index: 200;
    transition: 1.25s;
    background-color: #cee8ce;
    overflow: hidden;
    box-shadow: 4px 4px 10px #bbd1c0, -4px -4px 10px #bb96b2;
    /* 边界模糊不动*/
}

.switch_circle {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background-color: #cde9d0;
    box-shadow: inset 8px 8px 12px #FBD7DF, inset -8px -8px 12px #c6d1c8;
    /* 边界模糊不动*/
    bottom: -60%;
    left: -60%;
    transition: 1.25s;
}

.switch_circle-t {
    top: -30%;
    left: 60%;
    width: 300px;
    height: 300px;
}

.switch_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    width: 400px;
    padding: 50px 55px;
    transition: 1.25s;
}

.switch_button {
    cursor: pointer;
}

.switch_button:hover,
.submit:hover {
    box-shadow: 6px 6px 10px #d1d9e6, -6px -6px 10px #f9f9f9;
    /* 阴影 */
    transform: scale(0.985);
    transition: 0.25s;
}

.switch_button:active,
.switch_button:focus {
    box-shadow: 2px 2px 6px #d1d9e6, -2px -2px 6px #f9f9f9;
    /* 阴影 */
    transform: scale(0.97);
    transition: 0.25s;
}

.is-txr {
    left: calc(100% - 400px);
    transition: 1.25s;
    transform-origin: left;
}

.is-txl {
    left: 0;
    transition: 1.25s;
    transform-origin: right;
}

.is-z {
    z-index: 200;
    transition: 1.25s;
}

.is-hidden {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: 1.25s;
}

.is-gx {
    animation: is-gx 1.25s;
}

.auth-code {
    width: 111px;
    height: 36px;
    background: #fff;
}


@keyframes is-gx {

    0%,
    10%,
    100% {
        width: 400px;
    }

    30%,
    50% {
        width: 500px;
    }
}
</style>