<template>
    <div class="tabs">
        <h3 v-if="title !== undefined" class="title">{{ title }}</h3>
        <div class="tab-headers">
            <div
                v-for="(tab, index) in tabs"
                :key="index"
                :class="{ active: selectedIndex === index }"
                class="tab-header"
                @click="selectTab(index)"
            >
                {{ tab.name }}
            </div>
            <div :style="underlineStyle" class="tab-underline"></div>
        </div>
        <div class="tab-contents">
            <div v-for="(tab, index) in tabs" :key="index" class="tab-content">
                <div v-show="selectedIndex === index" class="slot">
                    <slot :name="tab.slotName"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TabComponent',
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            default: undefined,
        },
    },
    watch: {
        '$i18n.locale'() {
            console.log("i18n更新了")
            this.$forceUpdate(); // 强制重新渲染组件
        }
    },

    data() {
        return {
            selectedIndex: 0,
            underlineWidth: 0,
            underlineLeft: 0,
        };
    },
    computed: {
        underlineStyle() {
            return {
                width: `${this.underlineWidth}px`,
                transform: `translateX(${this.underlineLeft}px)`,
            };
        },
    },
    methods: {
        selectTab(index) {
            this.selectedIndex = index;
            this.$nextTick(() => {
                const activeTab = this.$el.querySelectorAll('.tab-header')[index];
                this.underlineWidth = activeTab.offsetWidth;
                this.underlineLeft = activeTab.offsetLeft;
            });
        },
        addResizeEvent() {
            window.addEventListener('resize', () => this.selectTab(this.selectedIndex))
        }
    },
    mounted() {
        this.selectTab(this.selectedIndex);
        this.addResizeEvent();
    },
};
</script>

<style scoped>
.tabs {
    width: 100%;
    position: relative; /* Ensures the underline is positioned correctly */
}

.title {
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: 30px;
    margin: 0 20px 20px;
}

.tab-headers {
    display: flex;
    justify-content: space-around;
    height: 50px;
    border-bottom: 1px solid #ccc;
    position: relative; /* Needed for absolute positioning of the underline */
}

.tab-header {
    padding: 0 25px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    font-size: 25px;
    position: relative;
    /* Ensure relative positioning for accurate underline placement */
}

.tab-header.active {
    color: #4da9c9;
}

.tab-header:hover {
    color: #4da9c9;
}

.tab-underline {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #4da9c9;
    transition: transform 0.3s ease, width 0.3s ease;
}

.tab-contents {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-content {
    width: 100%;
}
</style>
