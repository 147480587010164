<script>
import CardComponents from "@/components/index/Card.vue";

export default {
    name: 'XinWen',
    components: {CardComponents},
    data() {
        return {
            projectActivities: null,
        }
    },
    methods: {
        getProjectActivities() {
            this.$axios({
                method: 'get',
                url: 'api/common/article/list',
                headers: {
                    'Content-Type': "application/json;charset=UTF-8"
                },
            }).then(res => {
                if (res.data.code === 1) {
                    this.projectActivities = res.data.data;
                } else {
                    console.log(res.data.message);
                }
            })
        },
    },
    mounted() {
        /*还没弄好*/
        this.getProjectActivities();
        document.addEventListener("DOMContentLoaded", () => {
            const totalNewsItems = 8; // 新闻项目的总数
            const itemsPerPage = 6; // 每页显示的新闻项目数量
            const totalPages = Math.ceil(totalNewsItems / itemsPerPage); // 计算总页数
            let currentPage = 1; // 当前页码
            const paginationNumbers = document.querySelectorAll('.pagination-number');
            const prevButton = document.getElementById('pagination-prev');
            const nextButton = document.getElementById('pagination-next');

            function updatePagination() {
                paginationNumbers.forEach(number => {
                    number.classList.remove('active');
                    if (parseInt(number.dataset.page) === currentPage) {
                        number.classList.add('active');
                    }
                });
                // 控制省略号按钮
                const ellipsis = document.querySelector('.pagination-number.ellipsis');
                if (currentPage >= 3) {
                    ellipsis.textContent = currentPage; // 更新省略号按钮的文本为当前页码
                } else {
                    ellipsis.textContent = '...';
                }
                prevButton.classList.toggle('disabled', currentPage === 1);
                nextButton.classList.toggle('disabled', currentPage === totalPages);
            }

            function goToPage(page) {
                if (page < 1 || page > totalPages) return;

                currentPage = page;
                updatePagination();

                const container = document.getElementById('news-container');
                const newsItems = container.querySelectorAll('.news-item');

                newsItems.forEach((item, index) => {
                    const start = (page - 1) * itemsPerPage;
                    const end = start + itemsPerPage;

                    if (index >= start && index < end) {
                        item.style.display = 'flex';
                    } else {
                        item.style.display = 'none';
                    }
                });
            }

            paginationNumbers.forEach(number => {
                number.addEventListener('click', function () {
                    const page = parseInt(this.dataset.page);
                    if (page !== currentPage) {
                        goToPage(page);
                    }
                });
            });

            prevButton.addEventListener('click', function () {
                if (currentPage > 1) {
                    goToPage(currentPage - 1);
                }
            });

            nextButton.addEventListener('click', function () {
                if (currentPage < totalPages) {
                    goToPage(currentPage + 1);
                }
            });

            // 点击省略号按钮的处理
            const ellipsis = document.querySelector('.pagination-number.ellipsis');
            // 修改省略号按钮的点击处理逻辑
            ellipsis.addEventListener('click', function () {
                // 获取省略号当前显示的页码
                const currentEllipsisPage = parseInt(paginationNumbers[1].textContent);

                if (!isNaN(currentEllipsisPage)) {
                    // 计算下一个页码范围的起始页码
                    const nextStartPage = currentEllipsisPage + 1;
                    const nextEndPage = nextStartPage + 1;
                    // 更新小按钮的页码显示
                    paginationNumbers[0].textContent = nextStartPage;
                    paginationNumbers[0].dataset.page = nextStartPage;

                    paginationNumbers[1].textContent = nextEndPage;
                    paginationNumbers[1].dataset.page = nextStartPage;
                }
            });
            // 初始化加载第一页的新闻项目
            goToPage(1);
            // 初始化分页
            updatePagination();
            // 初始化加载第一页的新闻项目
            goToPage(1);
        });
    },
}
</script>


<template>
    <div class="container">
        <div class="main-box">
            <div class="title-box">{{ $t("h.newsCenterProjectDynamics.newsCenterProjectDynamics") }}</div>
            <div id="news-container" class="news-container">
                <!--        <div class="news-item with-image">
                          <div class="news-image news-image-1"></div>
                          <div class="news-title-box">
                            <h3 class="news-title">新闻标题 1</h3>
                          </div>
                          <div class="news-date-box">
                            <p class="news-date">12月25日，2023年</p>
                          </div>
                          <div class="news-read-more-box">
                            <router-link :to="{ name: 'NewsDetail', params: { id: 1 }}" class="news-read-more">点击阅读更多
                            </router-link>
                          </div>
                        </div>
                        <div class="news-item with-image">
                          <div class="news-image news-image-2"></div>
                          <div class="news-title-box">
                            <h3 class="news-title">新闻标题 2</h3>
                          </div>
                          <div class="news-date-box">
                            <p class="news-date">11月20日，2023年</p>
                          </div>
                          <div class="news-read-more-box">
                            <router-link :to="{ name: 'NewsDetail', params: { id: 2 }}" class="news-read-more">点击阅读更多
                            </router-link>
                          </div>
                        </div>
                        <div class="news-item with-image">
                          <div class="news-image news-image-3"></div>
                          <div class="news-title-box">
                            <h3 class="news-title">新闻标题 3</h3>
                          </div>
                          <div class="news-date-box">
                            <p class="news-date">10月15日，2023年</p>
                          </div>
                          <div class="news-read-more-box">
                            <router-link :to="{ name: 'NewsDetail', params: { id: 3 }}" class="news-read-more">点击阅读更多
                            </router-link>
                          </div>
                        </div>
                        <div class="news-item with-image">
                          <div class="news-image news-image-4"></div>
                          <div class="news-title-box">
                            <h3 class="news-title">新闻标题 4</h3>
                          </div>
                          <div class="news-date-box">
                            <p class="news-date">9月10日，2023年</p>
                          </div>
                          <div class="news-read-more-box">
                            <router-link :to="{ name: 'NewsDetail', params: { id: 4 }}" class="news-read-more">点击阅读更多
                            </router-link>
                          </div>
                        </div>
                        <div class="news-item with-image">
                          <div class="news-image news-image-1"></div>
                          <div class="news-title-box">
                            <h3 class="news-title">新闻标题 5</h3>
                          </div>
                          <div class="news-date-box">
                            <p class="news-date">8月5日，2023年</p>
                          </div>
                          <div class="news-read-more-box">
                            <router-link :to="{ name: 'NewsDetail', params: { id: 5 }}" class="news-read-more">点击阅读更多
                            </router-link>
                          </div>
                        </div>
                        <div class="news-item with-image">
                          <div class="news-image news-image-2"></div>
                          <div class="news-title-box">
                            <h3 class="news-title">新闻标题 6</h3>
                          </div>
                          <div class="news-date-box">
                            <p class="news-date">7月1日，2023年</p>
                          </div>
                          <div class="news-read-more-box">
                            <router-link :to="{ name: 'NewsDetail', params: { id: 6 }}" class="news-read-more">点击阅读更多
                            </router-link>
                          </div>
                        </div>
                        <div class="news-item with-image" style="display: none;">
                          <div class="news-image news-image-3"></div>
                          <div class="news-title-box">
                            <h3 class="news-title">新闻标题 7</h3>
                          </div>
                          <div class="news-date-box">
                            <p class="news-date">6月20日，2023年</p>
                          </div>
                          <div class="news-read-more-box">
                            <router-link :to="{ name: 'NewsDetail', params: { id: 7 }}" class="news-read-more">点击阅读更多
                            </router-link>
                          </div>
                        </div>
                        <div class="news-item with-image" style="display: none;">
                          <div class="news-image news-image-4"></div>
                          <div class="news-title-box">
                            <h3 class="news-title">新闻标题 8</h3>
                          </div>
                          <div class="news-date-box">
                            <p class="news-date">5月15日，2023年</p>
                          </div>
                          <div class="news-read-more-box">
                            <router-link :to="{ name: 'NewsDetail', params: { id: 8 }}" class="news-read-more">点击阅读更多
                            </router-link>
                          </div>
                        </div>-->
                <router-link
                    v-for="(item) in projectActivities" :key="item.id"
                    :to="{ name: 'NewsDetail', params: { id: item.id }}"
                    class="card">
                    <CardComponents :data="item" data-id="item.id"/>
                </router-link>
            </div>
        </div>
        <!--
            <div class="pagination-container">
              <div id="pagination-prev" class="pagination-box pagination-prev">上一页</div>
              <div id="pagination-numbers" class="pagination-numbers">

                <div class="pagination-number active" data-page="1">1</div>
                <div class="pagination-number" data-page="2">2</div>
                <div class="pagination-number ellipsis" data-page="3">...</div>
                <div class="pagination-number" data-page="10">10</div>
              </div>
              <div id="pagination-next" class="pagination-box pagination-next">下一页</div>
            </div> -->
    </div>
</template>

<style scoped>
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
}

.container {
    width: 85vw;
    margin: 0px auto;
}

.main-box {
    margin: 70px auto;
    position: relative;
}

.title-box {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #333;
    padding: 20px;
}

.news-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
    padding: 1.3vw;
    background-color: #ffffff;
}

/*

.news-item {
  background-color: #f0f0f0;
  padding: 2vw;
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
  overflow: hidden;
  flex: 1 1 calc(50% - 2vw);
  min-height: 25vw;
  border: 0.1vw solid #ddd;
}

.news-item:hover {
  transform: translateY(-0.5vw);
  box-shadow: 0 0.4vw 1.2vw rgba(0, 0, 0, 0.2);
}

.news-item.with-image {
  padding: 0;
  position: relative;
}

.news-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.news-title-box {
  position: absolute;
  top: 0.5vw;
  left: 1vw;
  height: 3vw;
  background-color: rgba(251, 200, 149, 0.9);
  padding: 1vw;
  z-index: 2;
}

.news-date-box {
  position: absolute;
  bottom: 4vw;
  left: 1vw;
  background-color: rgba(251, 200, 149, 0.9);
  padding: 0.5vw 1vw;
  z-index: 2;
}

.news-read-more-box {
  position: absolute;
  bottom: 1vw;
  left: 1vw;
  background-color: rgba(251, 200, 149, 0.9);
  padding: 0.5vw 1vw;
  z-index: 2;
  cursor: pointer;
}

.news-title,
.news-date,
.news-read-more {
  margin: 0;
  color: #333;
}

.news-title {
  font-size: 1vw;
  font-weight: bold;
}

.news-date,
.news-read-more {
  font-size: 0.8vw;
  margin: 0;
  color: #000;
  text-decoration: none;
}

.news-image-1 {
  background-image: url('tupian/1.jpg');
}

.news-image-2 {
  background-image: url('tupian/2.jpg');
}

.news-image-3 {
  background-image: url('tupian/3.jpg');
}

.news-image-4 {
  background-image: url('tupian/7.jpg');
}
*/

.pagination-container {
    width: 100%;
    margin: 2vw 0;
    background-color: #f0f0f0;
    border: 0.1vw solid #ddd;
    padding: 1vw;
    text-align: center;
}

.pagination-box {
    display: inline-block;
    margin: 0 1vw;
    padding: 0.5vw 1vw;
    background-color: #ffffff;
    border: 0.1vw solid #ddd;
    cursor: pointer;
    font-size: 1vw;
    color: #333;
}

.pagination-box:hover {
    background-color: #ddd;
}

/*.pagination-box.disabled {
  cursor: not-allowed;
  color: #ccc;
}*/

.pagination-numbers {
    display: inline-block;
    margin: 0 1vw;
}

.pagination-number {
    display: inline-block;
    width: 2vw;
    height: 2vw;
    line-height: 2vw;
    text-align: center;
    background-color: #ffffff;
    border: 0.1vw solid #ddd;
    margin: 0;
    cursor: pointer;
    font-size: 1vw;
    color: #333;
}

.card {
    flex-basis: calc(50% - 1vw);
}

.pagination-number:hover {
    background-color: #ddd;
}

.pagination-number.active {
    background-color: #333;
    color: #fff;
}

@media (max-width: 768px) {
    /*  .news-item {
        flex: 1 1 100%;
      }*/
    .card {
        flex-basis: 100%;
    }
}

@media (max-width: 480px) {
    .header h1 {
        font-size: 4vw;
    }

    /*

    .news-title {
      font-size: 2vw;
    }

    .news-date,
    .news-read-more {
      font-size: 1.5vw;
    }
    */
    .pagination-box,
    .pagination-number {
        padding: 0.8vw;
        font-size: 1.5vw;
    }
}
</style>
