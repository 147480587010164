<template>
    <div class="container">
        <div class="main-box">
            <div class="title-box">新闻中心 - 项目公告</div>
            <div class="news-container" ref="newsContainer">
                <div v-for="(newsItem, index) in visibleNewsItems" :key="index" class="news-item">
                    <h3 class="news-title">{{ newsItem.title }}</h3>
                    <hr class="news-item-separator">
                    <p class="news-description">{{ newsItem.description }}</p>
                    <div class="news-read-more-box">
                        <p class="news-read-more">点击阅读更多</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="pagination-container">
            <div class="pagination-box pagination-prev" @click="prevPage" :class="{ disabled: currentPage === 1 }">上一页</div>
            <div class="pagination-numbers">
                <div
                    v-for="page in paginationPages"
                    :key="page"
                    class="pagination-number"
                    :class="{ active: page === currentPage }"
                    @click="goToPage(page)"
                >
                    {{ page === ellipsisPage ? '...' : page }}
                </div>
            </div>
            <div class="pagination-box pagination-next" @click="nextPage" :class="{ disabled: currentPage === totalPages }">下一页</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GongGao',
    data() {
        return {
            totalNewsItems: 8, // 新闻项目的总数
            itemsPerPage: 6, // 每页显示的新闻项目数量
            currentPage: 1, // 当前页码
            newsItems: [
                { title: '网站公告 1', description: '这是网站公告的简短描述。这是网站公告的简短描述。这是网站公告的简短描述。' },
                { title: '网站公告 2', description: '这是网站公告的简短描述。这是网站公告的简短描述。这是网站公告的简短描述。' },
                { title: '网站公告 3', description: '这是网站公告的简短描述。这是网站公告的简短描述。这是网站公告的简短描述。' },
                { title: '网站公告 4', description: '这是网站公告的简短描述。这是网站公告的简短描述。这是网站公告的简短描述。' },
                { title: '网站公告 5', description: '这是网站公告的简短描述。这是网站公告的简短描述。这是网站公告的简短描述。' },
                { title: '网站公告 6', description: '这是网站公告的简短描述。这是网站公告的简短描述。这是网站公告的简短描述。' },
                { title: '网站公告 7', description: '这是网站公告的简短描述。这是网站公告的简短描述。这是网站公告的简短描述。' },
                { title: '网站公告 8', description: '这是网站公告的简短描述。这是网站公告的简短描述。这是网站公告的简短描述。' }
            ]
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalNewsItems / this.itemsPerPage);
        },
        visibleNewsItems() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.newsItems.slice(start, end);
        },
        paginationPages() {
            const pages = [];
            for (let i = 1; i <= this.totalPages; i++) {
                pages.push(i);
            }
            return pages;
        },
        ellipsisPage() {
            return this.currentPage > 3 ? 3 : null;
        }
    },
    methods: {
        goToPage(page) {
            if (page !== this.currentPage && page > 0 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.goToPage(this.currentPage - 1);
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.goToPage(this.currentPage + 1);
            }
        }
    }
};
</script>

<style scoped>
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
}

.container {
    max-width: 80%;
    margin: 0 auto;
    padding: 2vw;
}

.main-box {
    position: relative;
    background-color: #f0f0f0;
    padding: 1.7vw; 
    border: 0.1vw solid #ddd; 
}

.title-box {
    position: absolute;
    top: 2vw; 
    left: 1.7vw; 
    background-color: #ffffff;
    padding: 0.7vw 1vw; 
    font-size: 1.1vw; 
    font-weight: bold;
    color: #242424;
    border: 0.1vw solid #ddd; 
}

.news-container {
    margin-top: 4vw; 
    display: flex;
    flex-wrap: wrap;
    gap: 2vw; 
    border: 0.1vw solid #ddd; 
    padding: 1.3vw; 
    background-color: #ffffff;
}

.news-item {
    background-color: #f0f0f0;
    padding: 2vw; 
    transition: transform 0.2s, box-shadow 0.2s;
    position: relative;
    overflow: hidden;
    flex: 1 1 calc(50% - 2vw); 
    min-height: 22vw; 
    border: 0.1vw solid #ddd; 
}

.news-item:hover {
    transform: translateY(-0.5vw); 
    box-shadow: 0 0.4vw 1.2vw rgba(0, 0, 0, 0.2); 
}

.news-title {
    font-size: 1.2vw;
    font-weight: bold;
    text-align: center;
    color: #242424;
}

.news-description {
    font-size: 0.9vw;
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1.5;
}

.news-item-separator {
    width: 80%;
    margin: 10px auto;
    border-top: 1.4px solid #d4d2d2;
}

.news-read-more-box {
    position: absolute;
    bottom: 1vw; 
    left: 1vw; 
    background-color: rgba(251, 200, 149, 0.9);
    padding: 0.5vw 1vw; 
    z-index: 2;
    cursor: pointer;
}

.news-read-more {
    font-size: 0.8vw;
    margin: 0;
    color: #000;
    text-decoration: none;
}

.pagination-container {
    width: 100%;
    margin: 2vw 0; 
    background-color: #f0f0f0;
    border: 0.1vw solid #ddd; 
    padding: 1vw; 
    text-align: center;
}

.pagination-box {
    display: inline-block;
    margin: 0 1vw;
    padding: 0.5vw 1vw;
    background-color: #ffffff;
    border: 0.1vw solid #ddd; 
    cursor: pointer;
    font-size: 1vw;
    color: #333;
}

.pagination-box:hover {
    background-color: #ddd;
}

.pagination-box.disabled {
    cursor: not-allowed;
    color: #ccc;
}

.pagination-numbers {
    display: inline-block;
    margin: 0 1vw;
}

.pagination-number {
    display: inline-block;
    width: 2vw;
    height: 2vw;
    line-height: 2vw;
    text-align: center;
    background-color: #ffffff;
    border: 0.1vw solid #ddd; 
    margin: 0;
    cursor: pointer;
    font-size: 1vw;
    color: #333;
}

.pagination-number:hover {
    background-color: #ddd;
}

.pagination-number.active {
    background-color: #333;
    color: #fff;
}

@media (max-width: 768px) {
    .news-item {
        flex: 1 1 100%;
    }
}

@media (max-width: 480px) {
    .header h1 {
        font-size: 4vw;
    }

    .news-title {
        font-size: 2vw;
    }

    .news-description,
    .news-read-more {
        font-size: 1.5vw;
    }

    .pagination-box,
    .pagination-number {
        padding: 0.8vw;
        font-size: 1.5vw;
    }
}
</style>
