<script>
import LanSwitcher from "@/components/index/LanSwitcher.vue";

export default {
    name: 'HeaderComponent',
    components: {LanSwitcher},
    data() {
        return {
            isExpanded: false,
            windowWidth: 0,
            language: this.$i18n.locale,
            currentLanguage: null,
        };
    },
    methods: {
        hideMenu() {
            this.isExpanded = false;
        },
        changeLangEvent(lang) {
            if (lang === 'English') {
                localStorage.setItem('lang', 'en');
                this.setLanguage(localStorage.getItem('lang'));
            } else if (lang === '中文') {
                localStorage.setItem('lang', 'zh');
                this.setLanguage(localStorage.getItem('lang'));
            }
            window.location.reload(); // 强制刷新页面
        },
        setLanguage(lang) {
            this.$i18n.locale = lang;
        }
    },
    mounted() {
        this.setLanguage(localStorage.getItem('lang'));
    }
}
</script>

<template>
    <div id="header">
        <div class="hd-content">
            <!--     头部logo部分-->
            <div class="logo">
                <router-link class="logo-link" to="/">
                    <img alt="logo" class="logo-img" src="../../assets/images/logo-tran.png"/>
                </router-link>
            </div>
            <!--     头部左部分-->
            <div class="hd-right">
                <div class="hd-right-top">
                    <div class="hd-searcher">
                        <input :placeholder="$t('h.header.placeholder')" class="hd-s-input" type="text">
                        <i class="iconfont hd-s-icon">&#xe6cc;</i>
                    </div>
                    <LanSwitcher @change-lang="changeLangEvent"/>
                </div>
                <div class="hd-right-body">
                    <ul class="hd-nav">
                        <li class="hd-item">
                            <router-link class="hd-item-link" to="/">{{ $t("h.header.home") }}</router-link>
                        </li>
                        <li class="hd-item">
                            <router-link class="hd-item-link" to="/intro">{{ $t("h.header.projectIntro") }}
                            </router-link>
                        </li>
                        <li class="hd-item">
                            <router-link class="hd-item-link" to="/XinWen">{{ $t("h.header.newsCenter") }}</router-link>
                        </li>
                        <li class="hd-item">
                            <router-link class="hd-item-link" to="/AboutUs">{{ $t("h.header.aboutUs") }}</router-link>
                        </li>
                        <li class="hd-item">
                            <router-link class="hd-item-link" to="/OurTeam">{{ $t("h.header.ourteam") }}</router-link>
                        </li>
                        <li class="hd-item">
                            <router-link class="hd-item-link" to="/contact">{{ $t("h.header.joinUs") }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="sign-box">

                <!--        移动端导航栏-->
                <i class="hd-menu-btn iconfont" @click="isExpanded = !isExpanded">
                    &#xe66a;
                </i>
            </div>


        </div>
        <el-collapse-transition>
            <div v-show="isExpanded">
                <div v-show="isMobile" class="hd-menu">
                    <div class="hd-m-searcher">
                        <input :placeholder="$t('h.header.placeholder')" class="hd-m-sc-input" type="text">
                        <i class="iconfont hd-m-sc-icon">&#xe6cc;</i>
                    </div>
                    <ul>
                        <li class="hd-m-option" @click="hideMenu()">
                            <router-link class="hd-m-link" to="/">{{ $t("h.header.home") }}</router-link>
                            <i class="iconfont hd-m-icon">&#xe68e;</i>
                        </li>
                        <li class="hd-m-option" @click="hideMenu()">
                            <router-link class="hd-m-link" to="intro">{{ $t("h.header.projectIntro") }}
                            </router-link>
                            <i class="iconfont hd-m-icon">&#xe68e;</i>
                        </li>
                        <li class="hd-m-option" @click="hideMenu()">
                            <router-link class="hd-m-link" to="XinWen">{{ $t("h.header.newsCenter") }}</router-link>
                            <i class="iconfont hd-m-icon">&#xe68e;</i>
                        </li>
                        <li class="hd-m-option" @click=hideMenu()>
                            <router-link class="hd-m-link" to="AboutUs">{{ $t("h.header.aboutUs") }}</router-link>
                            <i class="iconfont hd-m-icon">&#xe68e;</i>
                        </li>
                        <li class="hd-m-option" @click="hideMenu()">
                            <router-link class="hd-m-link" to="contact">{{ $t("h.header.joinUs") }}</router-link>
                            <i class="iconfont hd-m-icon">&#xe68e;</i>
                        </li>
                    </ul>
                </div>
            </div>
        </el-collapse-transition>
    </div>
</template>


<style scoped>
a:hover {
    color: #4da9c9;
}

#header {
    width: 100%;
    background-color: #fff;


}

.hd-content {
    height: 100px;
    width: 100%;
    padding: 10px 20px 0;
    display: flex;
    justify-content: space-between;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

/*
  头部左边部分logo
 */
.logo {
    height: 100%;
    width: fit-content;
    margin-left: 80px;
}

.logo-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.logo-img {
    width: 310px;
    height: 100%;
}

/*
  头部右边部分->顶部
 */

.hd-right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.hd-right-top {
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 5px;
}

/*
   头部右边部分->搜索框
 */

.hd-searcher {
    border: 2px solid rgba(48, 39, 97, 1);
    border-radius: 15px;
    display: flex;
    height: 100%;
    width: fit-content;
    align-content: center;
    margin-right: 2%;
}

.hd-s-input {
    border: none;
    outline: none;
    padding: 0 4px 0 12px;
    background: none;
    font-size: 14px;
    color: #2b2b2b;
    width: 50px;
    transition: all 0.3s ease-in-out;
}

.hd-s-input:focus {
    width: 230px;
}

.hd-s-icon {
    padding: 4px 6px;
    text-align: center;
    cursor: pointer;
}

/*
  头部右边部分->登陆与注册
 */

.sign-link {
    padding: 0 5px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    color: rgba(48, 39, 97, 1);
}

/*
  手机端部分的登陆与注册
 */
.sign-box {
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

/*
  头部导航栏部分
 */

.hd-right-body {
    height: 65%;
}

.hd-nav {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
}

.hd-item {
    height: 100%;
    flex-basis: 110px;
}

.hd-item-link:hover {
    background-color: #4da9c9;
    color: #fff;
}

.hd-item-link {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: rgba(48, 39, 97, 1);
}

/*
  响应式布局之手机样式的菜单栏
 */
.hd-menu-btn {
    display: none;
    flex-wrap: wrap;
    justify-content: right;
    align-content: center;
    font-size: 36px;
}

.hd-menu {
    padding-top: 20px;
    height: fit-content;
    transition: all 1s ease-in-out;
}

.hd-m-searcher {
    height: 40px;
    margin: 0 10px 5px;
    display: flex;
    padding: 0 10px;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 10px;
}

.hd-m-sc-input {
    border: none;
    outline: none;
    color: #6d6e70;
    background: none;
    height: 100%;
    flex: 1;
}

.hd-m-sc-input::placeholder {
    color: #bfbfbf;
}

.hd-m-sc-icon {
    font-size: 20px;
    padding-right: 5px;
}

.hd-menu ul {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hd-m-option {
    width: 100%;
    height: fit-content;
    border-bottom: 1px solid #e3e9ed;
    display: flex;
    justify-content: space-between;
}

.hd-m-link {
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: left;
    height: 60px;
    flex-wrap: wrap;
    margin-left: 12px;
}

.hd-m-icon {
    display: flex;
    font-size: 20px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: left;
    width: 40px;
}


/*
  响应式布局之媒体查询
 */

/* 平板设备 */
@media (max-width: 1024px) {
    .logo {
        margin-left: 10px;
    }

    .logo-img {
        width: 200px;
        height: 63px;
    }

}


/* 响应式布局 */
/* 移动设备和小屏幕 */

@media (min-width: 1025px) {

}

/* 平板设备 */
@media (max-width: 1024px) {
    .logo {
        margin-left: 10px;
    }

    .logo-img {
        width: 200px;
        height: 63px;
    }

}


@media (max-width: 767px) {
    .hd-right {
        display: none;
    }

    .hd-menu-btn {
        display: flex;
        padding-left: 10px;
    }

    .hd-menu {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    }

    .sign-box {
        display: flex;
    }

}


/*
  动画效果
 */

</style>
