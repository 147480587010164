<template>
    <el-container v-loading.fullscreen="isLoading">
        <el-header>
            <div class="back" @click="backTo">
                <i class="el-icon-back"></i>
                <span>返回</span>
            </div>
            <el-input
                v-model="essay.title"
                maxlength="100"
                minlength="5"
                placeholder="请输入文章标题（5~100个字）"
                show-word-limit
            ></el-input>
            <el-button
                v-if="isEd" icon="el-icon-upload2" round
                type="primary"
                @click.native="uploadEssay()"
            >编辑
            </el-button>
            <el-button
                v-else icon="el-icon-upload2" round
                type="primary"
                @click.native="updateEssay()"
            >发布
            </el-button>
        </el-header>
        <el-main>
            <div style="border: 1px solid #ccc;">
                <Toolbar
                        :defaultConfig="toolbarConfig"
                        :editor="editor"
                        :mode="mode"
                        style="border-bottom: 1px solid #ccc"
                />
                <Editor
                    v-model="essay.content"
                    :defaultConfig="editorConfig"
                    :mode="mode"
                    style="height: 500px; overflow-y: hidden;"
                    @onCreated="onCreated"
                />
            </div>
            <div class="test editor-content-view" v-html="html">
            </div>
        </el-main>
    </el-container>

</template>

<script>
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'

export default {
    name: "EditorView",
    components: {Editor, Toolbar},
    data() {
        return {
            editor: null,
            html: '',
            toolbarConfig: {},
            editorConfig: {placeholder: '请输入内容...'},
            mode: 'default', // or 'simple'
            essay: {
                title: '',
                content: '',
            },
            method: '',
            id: null,
            isEd: null,
            isLoading: true,
        }
    },
    computed: {},
    methods: {
        updateEssay() {
            const {title} = this.essay;
            if (title.length < 5 || title.length > 100) {
                this.$message({
                    message: '文章标题长度应在 5 到 100 个字符之间',
                    type: 'warning',
                });
            } else {
                this.patchEssay(this.essay)
            }
        },
        uploadEssay() {
            const {title} = this.essay;
            if (title.length < 5 || title.length > 100) {
                this.$message({
                    message: '文章标题长度应在 5 到 100 个字符之间',
                    type: 'warning',
                });
            } else {
                this.postEssay(this.essay)
            }
        },
        getEssay(id) {
            this.$axios({
                method: 'get',
                url: 'https://apifoxmock.com/m1/5093316-4755738-default/common/article/1',
                headers: {
                    'Content-Type': 'application/json' // 确保设置正确的Content-Type
                },

            }).then(res => {
                if (res.data.code === 1) {
                    console.log(id)
                    const {title, content} = res.data.data;
                    this.essay.title = title;
                    this.essay.content = content;
                } else {
                    this.$message({
                        message: res.data.message,
                        type: 'error',
                    });
                }

            }).catch(error => {
                this.$message({
                    message: error.message,
                    type: 'error',
                });
            });
        },
        postEssay() {
            this.$axios({
                method: 'post',
                url: 'https://apifoxmock.com/m1/5093316-4755738-default/admin/article', // 替换为您的API端点
                data: this.essay,
                headers: {
                    'Content-Type': 'application/json' // 确保设置正确的Content-Type
                }
            }).then(res => {
                if (res.data.code === 1) {
                    this.$message({
                        message: '发送成功',
                        type: 'success',
                    });
                } else {
                    this.$message({
                        message: res.data.message,
                        type: 'error',
                    });
                }
            }).catch(error => {
                this.$message({
                    message: error.message,
                    type: 'error',
                });
            });
        },
        patchEssay() {
            this.$axios({
                method: 'patch',
                url: '', // 替换为您的API端点
                data: this.essay,
                headers: {
                    'Content-Type': 'application/json' // 确保设置正确的Content-Type
                }
            })
                .then(res => {
                    if (res.data.code === 1) {
                        this.$message({
                            message: '编辑成功',
                            type: 'success',
                        });
                    } else {
                        this.$message({
                            message: res.data.message,
                            type: 'error',
                        });
                    }
                })
                .catch(error => {
                    this.$message({
                        message: error.message,
                        type: 'error',
                    });
                });

        },
        backTo() {
            this.$router.back();
        },
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        judgeMethod() {
            this.id = this.$route.params.id
            if (this.$route.params.id != null) {
                this.isEd = true;
                this.getEssay(this.id)
            }
            this.isLoading = false
        },
    },
    mounted() {
        this.judgeMethod()
    }
    ,
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"/>
<style scoped>
.el-header {
    background-color: #f3f3f3;
}

.el-header {
    display: flex;
    align-items: center;
    gap: 20px;
}

.back {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    color: #333;
}

.el-icon-back {
    font-size: 24px;
}


.back span {
    display: inline-block;
    font-size: 20px;
    margin-left: 10px;

}

.el-input {
    flex: 1;
}

.editor-content-view {
    border: 3px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin-top: 20px;
    overflow-x: auto;
    font-family: 'Arial', sans-serif; /* 设置默认字体 */
}

.editor-content-view p {
    font-size: 16px; /* 设置段落字体大小 */
    line-height: 1.6; /* 设置段落行高 */
    margin: 10px 0; /* 设置段落外边距 */
    white-space: pre-wrap; /* 保留空格和换行 */
}

.editor-content-view ul {
    list-style: disc; /* 恢复无序列表的小黑点 */
    padding-left: 20px; /* 设置列表内边距 */
}

.editor-content-view ol {
    list-style: decimal; /* 恢复有序列表的数字标记 */
    padding-left: 20px; /* 设置列表内边距 */
}

.editor-content-view li {
    font-size: 16px; /* 设置列表项字体大小 */
    line-height: 1.6; /* 设置列表项行高 */
    margin-bottom: 5px; /* 设置列表项底部外边距 */
}

.editor-content-view blockquote {
    border-left: 4px solid #ccc; /* 设置引用边框样式 */
    padding: 10px 15px; /* 增加引用内边距 */
    margin: 10px 0; /* 设置引用外边距 */
    background-color: #f9f9f9; /* 设置引用背景颜色 */
    font-style: italic; /* 设置引用字体样式 */
}

.editor-content-view code {
    font-family: 'Consolas', monospace; /* 设置代码字体 */
    background-color: #f4f4f4; /* 设置代码背景颜色 */
    padding: 3px 5px; /* 增加代码内边距 */
    border-radius: 3px; /* 设置代码圆角 */
    color: #e74c3c; /* 设置代码颜色 */
}

.editor-content-view pre > code {
    display: block;
    padding: 10px;
    background-color: #282c34; /* 设置预格式化代码块背景颜色 */
    color: #abb2bf; /* 设置预格式化代码块字体颜色 */
    border-radius: 5px; /* 设置预格式化代码块圆角 */
    overflow-x: auto; /* 如果内容过长，允许水平滚动 */
}

.editor-content-view table {
    width: 100%; /* 设置表格宽度 */
    border-collapse: collapse;
    margin: 15px 0; /* 设置表格外边距 */
}

.editor-content-view td,
.editor-content-view th {
    border: 1px solid #ccc;
    padding: 8px 15px; /* 增加单元格内边距 */
    text-align: left; /* 设置单元格文本对齐方式 */
}

.editor-content-view th {
    background-color: #f1f1f1; /* 设置表头背景颜色 */
    font-weight: bold; /* 设置表头字体加粗 */
}

.editor-content-view a {
    color: #06c; /* 设置超链接颜色 */
    text-decoration: none; /* 设置超链接下划线 */
}

.editor-content-view a:hover {
    text-decoration: underline; /* 设置鼠标悬停时超链接的下划线 */
}

.editor-content-view strong {
    font-weight: bold; /* 设置加粗文本 */
}

.editor-content-view em {
    font-style: italic; /* 设置斜体文本 */
}

.editor-content-view img {
    max-width: 100%; /* 设置图片最大宽度 */
    height: auto; /* 保持图片的原始宽高比 */
    display: block; /* 设置图片为块级元素 */
    margin: 10px 0; /* 设置图片外边距 */
}

.editor-content-view input[type="checkbox"] {
    margin-right: 5px;
}

</style>