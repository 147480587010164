import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import IndexView from "@/views/homeViews/IndexView.vue";
import ProjectIntroduction from "@/views/homeViews/ProjectIntroduction.vue";
import AboutUs from "@/views/homeViews/AboutUs.vue";

import OurTeam from "@/views/homeViews/OurTeam.vue";

import XinWen from "@/views/homeViews/XinWen.vue";
import GongGao from "@/views/homeViews/GongGao.vue";
import ShiPin from "@/views/homeViews/ShiPin.vue";
import NewsDetail from "@/views/homeViews/NewsDetail.vue";
import VideoDetail from "@/views/homeViews/VideoDetail.vue";
import ContactView from "@/views/ContactView.vue";
import AdminLogin from "@/views/AdminLogin.vue";
import BackendView from "@/views/BackendView.vue";
import BackendHomeViews from "@/views/BackendViews/BackendHomeViews.vue"
import EditorView from "@/views/BackendViews/EditorView.vue";
import NewsCenter from "@/views/BackendViews/NewsCenter.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '',
        component: IndexView,
      },
      {
        path: 'intro',
        component: ProjectIntroduction,
      },
      {
        path: 'AboutUs',
        component: AboutUs,
      },

      {
        path: 'OurTeam',
        component: OurTeam,
      },
      
      {
        path: 'XinWen',
        component: XinWen,
      },
      {
        path: 'GongGao',
        component: GongGao,
      },
      {
        path: 'ShiPin',
        component: ShiPin,
      },
      {
        path: 'NewsDetail/:id',
        name: 'NewsDetail',
        component: NewsDetail,
      },
      {
        path: 'VideoDetail/:id',
        name: 'VideoDetail',
        component: VideoDetail,
      },
      {
        path: 'contact',
        name: 'contact',
        component: ContactView
      }
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: AdminLogin,
  },
  {
    path: '/back',
    name: 'back',
    component: BackendView,
    children: [
      {
        path: '',
        component: BackendHomeViews,
      },
      {
        path: 'news',
        component: NewsCenter,
      }
    ],
  },
  {
    path: '/editor',
    name: 'editor',
    component: EditorView,
  }
];

const router = new VueRouter({
  routes
})

export default router
