<template>
    <div>
        <el-container>
            <el-main>
                <el-row :gutter="20">
                    <el-col :span="4">
                        <el-input
                            v-model="titleSearch"
                            placeholder="请输入标题" round type="primary" @click.native="searchByTitle"></el-input>
                    </el-col>
                    <el-col :span="1">
                        <el-button>搜索</el-button>
                    </el-col>
                </el-row>
                <el-table
                    v-loading="isLoading"
                    :data="tableData"
                    :default-sort="{prop: 'publishTime', order: 'descending'}" :style="{width: '100%'}"
                    border="border"
                    max-height="500px"
                >
                    <el-table-column label="标题" prop="title" width=""></el-table-column>
                    <el-table-column label="阅读次数" prop="viewCount" sortable width=""></el-table-column>
                    <el-table-column label="发布时间" prop="publishTime" sortable width=""></el-table-column>
                    <el-table-column label="操作" prop="actions" width="">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                @click.native.prevent="handleEdit(scope.$index,tableData)">编辑
                            </el-button>
                            <el-button
                                size="mini"
                                type="danger"
                                @click.native.prevent="handleDelete(scope.$index, tableData)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-main>
        </el-container>
    </div>
</template>

<script>

export default {
    name: "NewsCenter",
    data() {
        return {
            tableData: [],
            isLoading: true,
            titleSearch: '',
        }
    },
    methods: {
        handleEdit(index, rows) {
            const id = rows[index].id;
            this.$router.push({name: 'editor', params: {id: id}})
        },
        handleDelete(index, rows) {
            console.log(rows.splice(index, 1));
        },
        getArticles() {
            this.$axios({
                method: 'get',
                url: 'api/common/article/list',
                // url: 'https://apifoxmock.com/m1/5093316-4755738-default/common/article/list',
                headers: {
                    'Content-Type': "application/json;charset=UTF-8"
                },
            }).then(res => {
                this.isLoading = false;
                if (res.data.code === 1) {
                    console.log(1)
                    this.tableData = res.data.data;
                } else {
                    console.log(res.data.message);
                }
            }).catch(err => {
                this.isLoading = false;
                this.$message({
                    type: 'error',
                    message: err.message,
                })
            })
        },
        getArticle(id) {
            this.$axios({
                method: 'get',
                url: `/common/article/${id}`,
                headers: {
                    'Content-Type': "application/json;charset=UTF-8"
                },
            }).then(res => {
                if (res.data.code === 1) {
                    this.tableData = res.data.data;
                } else {
                    console.log(res.data.message);
                }
            })
        },
        searchByTitle() {
            this.$axios({
                method: 'get',
                url: ``,
                headers: {
                    'Content-Type': "application/json;charset=UTF-8"
                },
            }).then(res => {
                console.log(res)
                // if (res.data.code === 1) {
                //
                // } else {
                //
                // }
            }).catch(error => {
                console.log(error.message)
            })
        }
    },
    mounted() {
        this.getArticles();
    }
}
</script>

<style scoped>

.el-main {
    gap: 20px;
}

.header {
    margin: 20px 0;
    display: flex;
    justify-content: left;
    gap: 10px;
}

.el-row {
    padding: 20px 0;
}

* {
    line-height: 0;
}

</style>