<template>
    <el-container class="container">
        <el-header>
            <div class="title">
                后台管理系统
            </div>
        </el-header>
        <el-container>
            <el-aside width="200px">
                <el-menu router="router">
                    <el-menu-item :index="{path:'/back/news'}">
                        <template slot="title">
                            <i class="el-icon-news"/>
                            <span>新闻中心</span>
                        </template>
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-main>
                <el-breadcrumb>
                    <el-breadcrumb-item>首页</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="box-card">
                    <router-view></router-view>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
export default {
    name: "backendView",
}
</script>

<style scoped>
.el-header {
    background-color: #353d40;
    color: #fff;
    text-align: center;
}

.title {
    text-align: left;
    font-size: 25px;
    line-height: 60px;
}

.el-aside {
    color: #333;
    text-align: center;
    line-height: 200px;
}

.el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    line-height: 160px;
    min-height: calc(100vh - 60px);
}

.box-card {
    background: #fff;
    margin-top: 20px;
}

</style>