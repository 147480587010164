
export default {
  data() {
    return {
      windowWidth: 0,
    };
  },

  computed:{
    isMobile(){
      return this.windowWidth < 767;
    }
  },
  methods: {
    getImageSrc(imageName) {
      try {
        return require(`@/assets/images/${imageName}`);
      } catch (e) {
        return '';
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  created() {
    this.handleResize();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
}