<template>
  <div class="circle-display">
     <div v-for="item in recordItems" :key="item.id" :style="{ backgroundImage: `url(${item.image})`,backgroundSize:'cover'}"
          class="circle">
       <div class="num">
         {{ item.num }}
       </div>
       <div class="describe">
         {{ item.describe }}
       </div>
     </div>
  </div>
</template>

<script>


export default {
  name: "CircleDisplayComponent",
  computed: {},
  props: {
    recordItems: {
      type: Array,
      default: undefined,
    }
  }
}
</script>

<style scoped>

.circle-display{
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
   row-gap: 40px;
}

.circle {
  background: #ffffff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px;
  color: #f8f8f8;
  font-size: 14px;
  padding-bottom: 23%;
  position: relative;
  width: 23%;
}

.num {
   font-size: 20px;
   font-weight: 700;
   height: 40px;
   position: absolute;
   right: 10%;
   text-align: center;
   top: 20%;
   width: 80%;

}

.describe {
   position: absolute;
   right: 5%;
   text-align: center;
   top: 45%;
   width: 90%;
}


/*
  响应式布局之媒体查询
 */

/* 响应式布局 */




@media (max-width: 1486px) {
  .circle {
    padding-bottom: 30%;
    width: 30%;
  }

  .num {
    font-size: 1.8vw;
  }

  .describe {
    font-size: 1.4vw;
  }
}

@media (max-width: 1140px) {
  .circle {
    padding-bottom: 30%;
    width: 30%;
  }

  .num {
    font-size: 1.8vw;
  }

  .describe {
    font-size: 1.4vw;
  }
}

/* 移动设备和小屏幕 */
@media (max-width: 767px) {
  .circle {
    font-size: 20px;
    padding-bottom: 45%;
    width: 45%;
  }

  .num {
    font-size: 3.2vw;
  }

  .describe {
    font-size: 2.2vw;
  }

}

@media (max-width: 446px) {

   .num {
      font-size: 13px;
   }

   .describe {
      font-size: 11px;
   }
}

</style>