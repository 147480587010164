

export const h = {
    //头部
    header: {
        home: 'Home',
        projectIntro: 'Introduction',
        newsCenter: 'News Center',
        aboutUs: 'About Us',
        joinUs: 'Join Us',
        placeholder: 'Search',
        ourteam: 'Our Team',
    },
    //底部
    footer: {
        cncDescription: 'CNC Development & Support is a non-profit organization dedicated to helping blind and visually impaired children in Nigeria.',
        siteAnnouncements: 'Site Announcements',
        projectUpdates: 'Project Updates',
        videoUpdates: 'Video Updates',
        contactUs: 'Contact Us',
        phone: 'Phone: 400-800-8888x',
        email: 'Email : aronkaibai@cncds.org',
        projectIntroduction: 'Project Introduction', // 新增  
        newsCenter: 'News Center', // 新增  
        aboutUs: 'About Us', // 新增  
        joinUs: 'Join Us', // 新增 
        copyright: '© 2024 CNC Development & Support. All rights reserved.' ,// 新增版权信息，并添加了“All rights reserved.”以增加清晰度
        ourteam: 'Our team'
    },
    //首页
    Home: {
        //新闻中心
        projectDynamics: 'Project Dynamics',
        newsCenter: 'News Center',
        readMore: 'Click to Read More',
        //照片库
        photoGallery: 'Photo Gallery',
        //我们的成就  	
        ourAchievements:
        {
            ourAchievements: 'Our Achievements',
            togetherForABrighterFuture: 'Together for a Brighter Future, Every Bit of Love 			Shines with Hope',
            educationSupport: 'Education Resource Assistance',
                blindChildrenAchievements: 'We have aided over 100 blind / visually impaired kids, granting access to education & igniting their potential.',
            donations: {
                title: 'Donations of Living and Learning Materials',
                details: [
                    'Donated writing gear, canes, Braille mats, tools for study.',
                    'Provided food & essentials for blind kids & families.'
                ]
            },
            campusUpgrades: 'Campus Facility Upgrades and Student Care',
            campusUpgradesDetails: [
                'Focusing on both learning & living, we upgraded dorm bedding & donated fans. ',
                'Plus, equipped music lovers with flutes & trumpets.'
            ],
            communityActivities: 'Community Activities and Health Education',
            communityActivitiesDetails: [
                'Collaborated with NGOs on events for community integration.',
                'Summer lectures for students on adolescent health & care.'
            ]
        }
    },
    //新闻中心
    newsCenterProjectDynamics: {
        newsCenterProjectDynamics: 'News Center - Project Dynamics',
        readMore: 'Read More',
        previousPage: 'Previous Page',
        nextPage: 'Next Page',
        newsDetails: 'News Details',
                backToNewsCenter: 'Back to News Center',
        NotFound:'The corresponding news details were not found.'
    },
    
    //加入我们
    joinUs: {
        contactUs: 'Contact Us',
        name: 'Name',
        nameCannotBeEmpty: 'Name cannot be empty',
        phoneNumber: 'Phone Number',
        phoneNumberFormatIncorrect: 'Phone Number format is incorrect',
        email: 'Email',
        emailFormatIncorrect: 'Email format is incorrect',
        message: 'Message',
        submit: 'Submit',
        aboutCNC: 'CNC Development & Support is an NGO located in Nigeria, dedicated to helping blind schools and visually impaired children access better education, learning, and living environments. Our goal is to empower blind and visually impaired children through the provision of high-quality educational resources and support services, enabling them to become self-sufficient and better integrated into society in the future. We strive to bridge the education gap and ensure that every blind and visually impaired child has access to fair educational opportunities.',
        viewMore: 'View More',
        cncOrganization: 'CNC Development & Support',
        address: 'Address',
        emailInfo: 'Email: aronkaibai@cncds.org',
        phoneNumberInfo: 'Phone Number'
    },
    //关于我们
    aboutUs: 'About Us',
    aboutUsContent: {
        p1: 'Since its establishment in Nigeria on September 25, 2023, CNC Development & Support has been dedicated to creating better educational, learning, and living environments for schools for the blind and visually impaired children. We understand that every child has the right to pursue knowledge and enjoy life, and we are committed to being a strong support on their journey.',
        p2: 'CNC Development & Support has achieved remarkable success so far. We have helped more than one hundred blind and visually impaired children gain access to valuable educational resources, opening the door to knowledge for them. In terms of material assistance, we provided 200 writing boards to improve the children’s writing skills, distributed 120 white canes to ensure their safe mobility, donated 40,000 sheets of Braille paper as ample learning materials, and supplied 120 Braille calculators to assist with math studies. Additionally, we introduced recreational items such as Braille Rubik’s cubes and Braille playing cards to enrich their extracurricular life, and donated over 1,000 kilograms of living essentials to support their basic needs. As for facilities, we replaced bedding in student dormitories and donated fans to ensure a comfortable environment during the summer. In arts and culture, we donated a variety of musical instruments to inspire the children’s interest in music. In community activities, we worked closely with local NGOs to successfully organize flea markets, fairs, and other events, fostering community engagement and raising public awareness of the challenges faced by the visually impaired. At the same time, we paid special attention to health education by holding hygiene seminars during the summer break, helping students establish correct health concepts and learn how to protect themselves. These efforts reflect CNC Development & Support’s relentless commitment to improving the living and educational conditions of blind and visually impaired children, along with the significant impact we have made.',
        p3: 'Every achievement of CNC Development & Support is made possible by the generous donations and selfless contributions of our supporters. Your support has not only provided tangible help to schools for the blind and visually impaired children but also inspired greater public attention and participation in charitable causes. We firmly believe that through our collective efforts, we can change the destinies of more children and create a brighter future for them. To further advance our mission, CNC Development & Support invites more kind-hearted individuals to join us in contributing to schools for the blind and visually impaired children. Every donation you make will become the sunshine and rain nourishing their growth. Please visit our official website or contact us to learn more about donation methods and details. Let’s work hand in hand to spread warmth and hope!'
    },
    ourMission: 'Our Mission',
    ourMissionContent: {
        section1: {
            title: '1. Educational Resource Provision',
            content: {
                p1: 'Basic Education Access: Our primary mission is to provide comprehensive foundational education resources to schools for the blind and visually impaired children, including customized textbooks, learning tools, and assistive technology to ensure every child has equal learning opportunities.',
                p2: 'Special Education Support: Considering the special needs of blind and visually impaired children in learning, we introduce and train professional special education teachers, adopting innovative teaching methods to help them overcome learning barriers and fully realize their potential.',
                p3: 'Learning Material Donations: We have successfully donated learning materials, including Braille paper, Braille calculators, and writing boards, ensuring that children have ample resources to open the door to knowledge.'
            }
        },
        section2: {
            title: '2. Improving Quality of Life',
            content: {
                p1: 'Material Aid: Beyond educational resources, we focus on the children’s basic needs, donating essential items such as food, clothing, and bedding to ensure they grow up in a healthy and comfortable environment.',
                p2: 'Health Education and Protection: We prioritize the health education of blind and visually impaired children, regularly hosting hygiene seminars to teach them knowledge and skills for self-protection, helping them establish proper health habits.',
                p3: 'Community Integration and Participation: We collaborate closely with local communities to organize various activities such as flea markets and fairs, enhancing community interaction, raising public awareness about the visually impaired, and helping the children integrate better into society.'
            }
        },
        section3: {
            title: '3. Psychological and Social Support',
            content: {
                p1: 'Psychological Counseling and Care: Recognizing the importance of mental health for blind and visually impaired children, we provide counseling services to help them build confidence and face life’s challenges with positivity.',
                p2: 'Fostering Interests and Talents: We encourage children to develop personal interests and talents by donating musical instruments and Braille Rubik’s cubes, enriching their extracurricular lives and fostering diverse skills.',
                p3: 'Social Advocacy and Awareness: Through our efforts, we aim to raise public awareness of blind and visually impaired children, advocating for inclusion and respect while securing more resources and support for them in society.'
            }
        },
        start:'Since its establishment in Nigeria on September 25, 2023, CNC Development&Support has shouldered a sacred and arduous task - to provide comprehensive educational resources for local blind children and strive to improve their quality of life. We deeply understand that the power of education can illuminate the path of hope and open up infinite possibilities for these special children.',
        closing: 'CNC Development & Support is keenly aware of the importance and honor of its mission. We will continue to uphold our values and commitment, providing more comprehensive and in-depth support for blind and visually impaired children. We believe that through our relentless efforts and the collective involvement of society, we can change the lives of more children and create a better future for them.'
    },
    ourStory: 'Our Story',
    ourStoryContent: {
        p1: 'Since its establishment in Nigeria on September 25, 2023, CNC Development & Support has been entrusted with a sacred and challenging mission – to provide comprehensive educational resources to blind children and improve their quality of life. We deeply understand that the power of education can light the path of hope, unlocking endless possibilities for these special children. The story of CNC Development & Support began with a group of passionate and visionary individuals who, in a hopeful year, decided to embark on an extraordinary journey together. Let us trace back to the warm spring when it all started. That year not only marked the birth of CNC but also heralded the beginning of a new chapter of love, hope, and change.',
        p2: 'At first, these people from different backgrounds, united by a common belief, came together to witness the immense challenges that blind and visually impaired children face in gaining education, integrating into society, and enjoying basic living conditions. They realized that although the world is advancing rapidly, these children are often forgotten, their voices and needs urgently needing to be heard and met. Thus, CNC Development & Support was born with a clear and unwavering vision: to create an accessible, opportunity-filled, and loving environment for blind and visually impaired children, enabling them to pursue knowledge and enjoy the beauty of life, just like everyone else.'
    },
    corePrinciples: 'Our Core Principles',
    corePrinciplesContent: {
        equality: 'Equality',
        respect: 'Respect',
        education: 'Education',
        care: 'Care'
    },

    //项目介绍
    DevSupport: ' CNC Development & Support ',
    projectOverview: 'Project Overview',
    projectOverviewContent: {
        p1: 'Blind and visually impaired children in Nigeria face many challenges in education. Due to a lack of resources, many schools for the blind lack the necessary teaching equipment and materials. Most of the existing blind supplies are damaged, making it difficult for these children to receive the same quality of education as other students. Traditional teaching methods are not suitable for them, and specialized equipment such as Braille books and Braille printers are extremely scarce. Furthermore, the low societal awareness of the needs of blind and visually impaired children creates significant barriers to their education and social integration.',
        p2: 'The goal of CNC Development & Support is to provide high-quality educational resources and support services to help blind and visually impaired children become self-reliant and better integrated into society in the future. We aim to bridge the educational gap and ensure that every blind and visually impaired child has access to equal educational opportunities. Specific goals include providing schools for the blind with Braille printers, Braille books, and other specialized teaching equipment; offering professional training to teachers to enhance their ability to teach blind and visually impaired students; organizing community activities to raise awareness of the educational challenges faced by blind and visually impaired children; and working with local governments and NGOs to explore more suitable employment training for blind and visually impaired students.'
    },
    projectMilestones: 'Project Milestones',
    projectMilestonesContent: {
        p1: 'CNC Development & Support has achieved remarkable success so far. We have helped more than one hundred blind and visually impaired children gain access to valuable educational resources, opening the door to knowledge for them. In terms of material assistance, we provided 200 writing boards to improve the children’s writing skills, distributed 120 white canes to ensure their safe mobility, donated 40,000 sheets of Braille paper as ample learning materials, and supplied 120 Braille calculators to assist with math studies. Additionally, we introduced recreational items such as Braille Rubik’s cubes and Braille playing cards to enrich their extracurricular life, and donated over 1,000 kilograms of living essentials to support their basic needs. As for facilities, we replaced bedding in student dormitories and donated fans to ensure a comfortable environment during the summer. In arts and culture, we donated a variety of musical instruments to inspire the children’s interest in music.',
        p2: 'In community activities, we worked closely with local NGOs to successfully organize flea markets, fairs, and other events, fostering community engagement and raising public awareness of the challenges faced by the visually impaired. At the same time, we paid special attention to health education by holding hygiene seminars during the summer break, helping students establish correct health concepts and learn how to protect themselves. These efforts reflect CNC Development & Support’s relentless commitment to improving the living and educational conditions of blind and visually impaired children, along with the significant impact we have made.',
        p3: 'Every achievement of CNC Development & Support is made possible by the generous donations and selfless contributions of our supporters. Your support has not only provided tangible help to schools for the blind and visually impaired children but also inspired greater public attention and participation in charitable causes. We firmly believe that through our collective efforts, we can change the destinies of more children and create a brighter future for them. ',
        p4: 'To further advance our mission, CNC Development & Support invites more kind-hearted individuals to join us in contributing to schools for the blind and visually impaired children. Every donation you make will become the sunshine and rain nourishing their growth. Please visit our official website or contact us to learn more about donation methods and details. Let’s work hand in hand to spread warmth and hope!'
        
    },
    expectedOutcomes: 'Expected Outcomes',
    expectedOutcomesContent: {
        expectedOutcome1: 'Improve the educational level of blind and visually impaired children, enabling them to receive educational opportunities equal to those of regular students.',
        expectedOutcome2: 'Enhance the life skills of blind and visually impaired children, helping them better adapt to social life.',
        expectedOutcome3: 'Increase public awareness of educational issues faced by blind and visually impaired children, and promote support for the cause of blind education from all sectors of society.',
        expectedOutcome4: 'Improve the quality of teaching by training teachers, thereby enhancing students'
    },
    DevSupport2: ' CNC Development & Support ',
    
    ourteamContent: {
        p1: 'President: Lijun Li',
        p2: 'Vice president: Yi Li, Junhong Li',
        p3: 'Member: Guanghui Xu, Jessica Chen'
    },

    
}
