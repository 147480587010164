<template>
  <div class="contact">
    <h2 class="title">{{ this.title }}</h2>
    <div class="body">
      <div class="bd-left">
        <div class="about">
          <div class="about-content">
            {{ $t("h.joinUs.aboutCNC") }}
          </div>
          <div class="about-more">
            <router-link to="/intro">{{ $t("h.joinUs.viewMore") }}</router-link>
          </div>
          <div class="address">
            {{ $t("h.joinUs.cncOrganization") }}<br/>
            <!-- {{ $t("h.joinUs.address") }}<br/> -->
            {{ $t("h.joinUs.emailInfo") }}<br/>
            <!-- {{ $t("h.joinUs.phoneNumberInfo") }}<br/> -->
          </div>
          <div class="bd-left-bg">

          </div>
        </div>
      </div>
      <div class="bd-right">
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <input
              v-model="this.formData.name"
              class="name-input input"
              :placeholder= "this.$t('h.joinUs.name')"
              @blur="validField('name')"
            />
            <p class="tip">{{ this.validResult.name }}</p>
          </div>
          <div class="form-group ">
            <input
              v-model="this.formData.phone"
              class="phone-input input"
              :placeholder= "this.$t('h.joinUs.phoneNumber')"
              @blur="validField('phone')"
            />
            <p class="tip">{{ this.validResult.phone }}</p>
          </div>
          <div class="form-group">
            <input
              v-model="this.formData.email"
              class="email-input input"
              :placeholder="this.$t('h.joinUs.email')"
              @blur="validField('email')"
            />
            <p class="tip">{{ this.validResult.email }}</p>
          </div>
          <div class="form-group last-words">
            <div class="words">
              <div class="w-title">
                {{ this.validResult.message }}
              </div>
              <textarea
                v-model="this.formData.words"
                class="w-content"
                @blur="validField('words')"
              />
              <p class="tip">{{ this.validResult.words }}</p>
            </div>
          </div>
          <button class="form-btn" type="submit">{{ $t("h.joinUs.submit") }}</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactView",
  data() {
    return {
      title: this.$t("h.joinUs.contactUs"),
      validResult: {
        name: '',
        phone: '',
        email: '',
        words: '',
      },
      formData: {
        name: '',
        phone: '',
        email: '',
        words: '',
      }
    };
  },
  methods: {
    handleSubmit() {
    },
    validField(field) {
      const value = this.formData[field];
      const phonePattern = /^[0-9]{10,15}%/
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let error = '';
      switch (field) {
        case 'name':
          error = value ? '' : this.$t("h.joinUs.nameCannotBeEmpty");
          break;
        case 'phone':
          error = phonePattern.test(value) ? '' : this.$t("h.joinUs.phoneNumberFormatIncorrect");
          break;
        case 'email':
          error = emailPattern.test(value) ? '' : this.$t("h.joinUs.emailFormatIncorrect");
      }
      this.validResult[field] = error;
    },
  },
};

</script>

<style scoped>
.contact {
  width: 85vw;
  margin: 50px auto;
}

.title {
  font-size: 35px;
  text-align: center;
  color: #000000;
}

.body {
  margin-top: 50px;
  display: flex;
  gap: 50px;
}

.bd-left,
.bd-right {
  flex-basis: 50%;
}


.about {
  color: #ffffff;
  position: relative;
  width: 100%;
  padding: 20px;
}

.about-content {
  font-size: 18px;
  line-height: 2rem;
  padding: 10px 0;
  text-indent: 2rem;
}


.about-more {
  line-height: 2rem;
  padding: 0 20px;
  background-color: #4da9c9;
  color: #ffffff;
  font-size: 17px;
  width: fit-content;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 14px;
  cursor: pointer;
}

.address {
  margin: 30px 0;
  text-align: center;
  line-height: 2rem;
  font-size: 18px;
}

.bd-left-bg {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background: url("@/assets/images/contact.jpg") center no-repeat;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-size: cover;
  filter: blur(1px) brightness(30%);
}

.bd-right {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 30px 50px 0;
}

.form-group {
  height: 65px;
}

.input {
  font-size: 16px;
  border: none;
  width: 100%;
  outline: none;
  height: 63%;
  border-bottom: #d8d8d8 1px solid;
  transition: all 0.3s ease-in-out;
}

.input:hover, .w-content:hover {
  border-color: #007bff;
}

.input:focus, .w-content:focus {
  border-color: #007bff;
}

.tip {
  height: 37%;
  color: #d20a2c;
  font-size: 12px;
  transform: translateY(5px);
}

.last-words {
  height: fit-content;
}

.words {
  height: fit-content;
}

.w-content {
  height: 116px;
  width: 100%;
  border: 1px solid #d8d8d8;
  outline: none;
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
  resize: none;
  padding: 10px;
  margin: 10px 0;
  transition: all 0.3s ease-in-out;
}


.form-btn {
    display: block;
    margin: 0 auto;
    background: none;
    border: #b2b2b2 2px solid;
    padding: 10px 40px;
    border-radius: 30px;
    font-size: 13px;
    cursor: pointer;
}


@media (min-width: 1025px) {

}

/* 平板设备 */
@media (max-width: 1024px) {
    .body {
        flex-direction: column;
    }

    .bd-right {
        padding-bottom: 30px;
    }
}


@media (max-width: 767px) {


}


</style>