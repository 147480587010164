import { render, staticRenderFns } from "./EditorView.vue?vue&type=template&id=cccc3888&scoped=true"
import script from "./EditorView.vue?vue&type=script&lang=js"
export * from "./EditorView.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./EditorView.vue?vue&type=style&index=1&id=cccc3888&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cccc3888",
  null
  
)

export default component.exports