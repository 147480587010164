<script>
export default {
  name: 'FooterComponents'
}
</script>

<template>
  <div id="footer">
    <div class="footer-content">
      <div class="ft-top">
        <div class="ft-logo">
          <img alt="ft-logo" class="logo-img" src="../../assets/images/logo-tran.png">
        </div>

        <p class="ft-group">{{ $t("h.footer.cncDescription") }}</p>
      </div>
      <div class="ft-body">
        <ul class="ft-table">
          <li class="ft-cell">
            <h2 class="ft-cell-top">{{ $t("h.footer.projectIntroduction") }}</h2>
            <ul class="ft-intro">
              <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="intro">{{ $t("h.footer.projectIntroduction") }}</router-link>
              </li>
              <!-- <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="">影响与成果</router-link>
              </li> -->
            </ul>
          </li>
          <li class="ft-cell">
            <h2 class="ft-cell-top">{{ $t("h.footer.newsCenter") }}</h2>
            <ul class="ft-intro">
              <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="GongGao">{{ $t("h.footer.siteAnnouncements") }} </router-link>
              </li>
              <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="XinWen">{{ $t("h.footer.projectUpdates") }}</router-link>
              </li>
              <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="ShiPin">{{ $t("h.footer.videoUpdates") }}</router-link>
              </li>

            </ul>
          </li>
          <li class="ft-cell">
            <h2 class="ft-cell-top">{{ $t("h.footer.aboutUs") }}</h2>
            <ul class="ft-intro">
              <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="AboutUs">{{ $t("h.footer.aboutUs") }}</router-link>
              </li>
              <!-- <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="">我们的团队</router-link>
              </li>
              <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="">资质证书</router-link>
              </li>
              <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="">发起人介绍</router-link>
              </li> -->
            </ul>
          </li>
          <li class="ft-cell">
            <h2 class="ft-cell-top">{{ $t("h.footer.ourteam") }}</h2>
            <ul class="ft-intro">
              <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="OurTeam">{{ $t("h.footer.ourteam") }} </router-link>
              </li>
            </ul>
          </li>
          <li class="ft-cell ft-cell-longer">
            <h2 class="ft-cell-top ">{{ $t("h.footer.contactUs") }}</h2>
            <ul class="ft-intro">

              <!-- <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="">{{ $t("h.footer.phone") }}</router-link>
              </li> -->
              <li class="ft-intro-item">
                <!-- <router-link class="ft-intro-link" to=""> </router-link>-->
                  {{ $t("h.footer.email") }}
              </li>
              <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="contact">{{ $t("h.footer.joinUs") }}</router-link>
              </li>

              <!-- <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="">志愿者招聘</router-link>
              </li>
              <li class="ft-intro-item">
                <router-link class="ft-intro-link" to="">与我们合作</router-link>
              </li> -->
            </ul>
          </li>
          <!-- <li class="ft-cell ft-cell-last">
            <ul class="qr-code-section">
              <li class="qr-code-section-item">
                  <img alt="" class="qr-code-img" src="../../assets/images/qr1.webp"/>
                <div class="qr-code-info">xxx</div>
              </li>
              <li class="qr-code-section-item">
                  <img alt="" class="qr-code-img" src="../../assets/images/qr1.webp"/>
                <div class="qr-code-info">xxx</div>
              </li>
            </ul>
          </li> -->
        </ul>
      </div>
      <div class="ft-bottom">
        {{ $t("h.footer.copyright") }}
      </div>
    </div>
  </div>
</template>

<style scoped>
#footer{
  border-top: 1px solid #e3e9ed;
  background-color:#e8f3ff;
}

.footer-content {
  height: 100%;
  width: 100%;
  padding: 10px 20px 0;
}

/*
  头部logo+团队简介
 */
.ft-top,
.ft-logo ,
.ft-group {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ft-top {
    gap: 5%;
}
.ft-logo{
  justify-content: center;
  width: 320px;
}
.ft-group{
  font-size: 24px;
  font-weight: 700;
}

/*
  列表系列
 */
.ft-body {
  height: fit-content;
  margin-top: 20px;
  padding: 10px 0;
}

.ft-table {
  height: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  row-gap: 30px;
  margin: 0 auto;
  width: 90%;
}
/*
  每一个单元格
 */
.ft-cell{
  flex-basis: 140px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /*border-left: 1px solid #e3e9ed;*/
  height: 150px;

}

.ft-cell-longer {
  flex-basis: fit-content;
}

/*
二维码
*/

.ft-cell-last {
  flex-grow: 0;
  min-width: 300px;
  border-right: 1px solid #e3e9ed;
}

.qr-code-section-item{
  text-align: center;
}

.qr-code-img {
  width: 80%;
}

.qr-code-info {
  margin-top: 15px;
  text-align: center;
}

.qr-code-section {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

/*
单元格头部
*/

.ft-cell-top {
  height: 30%;
  padding-bottom: 15px;
}

/*
单元格列表
*/

.ft-intro {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.ft-intro-link {
  font-size: 14px;
  white-space: nowrap;
}

/*
  版权尾部
 */
.ft-bottom {
  text-align: center;
  height: 30px;
  color: #8f8f8f;
  line-height: 30px;
  font-size: 12px;
  border-top: 1px solid #e3e9ed;
  margin-top: 20px;
}

.logo-img {

  width: 280px;
}

/*
响应式布局之媒体查询
*/


/* 响应式布局 */

@media (min-width: 1025px) {

}

/* 平板设备 */
@media (max-width: 1024px) {
  .ft-table {
    flex-wrap: wrap;
  }
  .ft-group{
    font-size: 18px;
  }
}


/* 移动设备和小屏幕 */
@media (max-width: 767px) {
  .ft-table {
    width: 100%;
    row-gap: 10px;
  }

  .ft-group {
    font-size: 18px;
  }

  .ft-top {
    gap: 20px;
  }

  .logo-img {
    width: 200px;
  }
}

@media (max-width:622px) {
  .ft-top{
    flex-direction: column;
    height: fit-content;
  }
  .ft-top{
    gap: 0;
  }
  .logo-img {
    width: 290px;
  }
}
/*
动画效果
*/


</style>