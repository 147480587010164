<template>
    <div>
        <div class="milestones-content">
            <br>
            <div class="title">
                <!-- {{ $t("h.aboutUs") }} -->
                Our Team
            </div>
            <img src="@/assets/images/z5.png" class="divider" alt="Divider">
            <div class="milestones-content">
                <div class="text-box">
                    <!-- 这里放置文本内容 -->
                    <p class="text-content">
                        {{ $t("h.ourteamContent.p1") }}
                    </p><br>
                    <p class="text-content">
                        {{ $t("h.ourteamContent.p2") }}
                    </p><br>
                    <p class="text-content">
                        {{ $t("h.ourteamContent.p3") }}
                    </p>
                    <br><br><br><br><br><br><br><br><br>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUs',
    data() {
        return {
            principles: [this.$t("h.corePrinciplesContent.equality"), this.$t("h.corePrinciplesContent.respect") , this. $t("h.corePrinciplesContent.education") , this.$t("h.corePrinciplesContent.care") ],
            selectedPrinciple: null,
            selectedSubtitles: [] // 存储已选择的子标题
        };
    },
    methods: {
        selectPrinciple(index) {
            this.selectedPrinciple = index;
        },
        toggleSubtitle(subtitle) {
            const index = this.selectedSubtitles.indexOf(subtitle);
            if (index === -1) {
                this.selectedSubtitles.push(subtitle);
            } else {
                this.selectedSubtitles.splice(index, 1);
            }
        }
    }
};
</script>


<style scoped>
/* 第三模块样式 */
.milestones-container {
    width: 100%;
    padding: 2vw;
    box-sizing: border-box;
}

.milestones-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.text-box {
    width: 80%;
    text-align: justify;
    margin-bottom: 2vw;
}

.divider {
    width: 20%;
}

.title {
    font-size: 2vw;
    text-align: center;
    margin-bottom: 1vw;
    font-weight: bold;
    color: #8192a3;
}

.core-principles-container {
    display: flex;
    justify-content: space-around;
    /* 均匀分布子元素 */
    align-items: center;
    /* 垂直居中 */
    width: 80vw;
    /* 容器宽度为视窗宽度 */
    padding: 1vw 0;
    /* 上下内边距，视情况调整 */
}

.circle {
    width: 12vw;
    /* 每个圆圈的宽度，这里简单使用vw单位分配，总宽度应小于或等于100vw */
    height: 12vw;
    /* 高度与宽度相同，保持圆形 */
    border-radius: 50%;
    /* 圆形边框 */
    display: flex;
    justify-content: center;
    /* 水平居中内容 */
    align-items: center;
    /* 垂直居中内容 */
    background-color: #f0f0f0;
    /* 背景色，可以根据需要调整 */
    margin: 0 0.3vw;
    /* 圆圈之间的间距，视情况调整 */
    font-size: 2.3vw;
    /* 字体大小，视情况调整 */
    font-weight: bold;
    /* 加粗字体 */
    text-align: center;
    transition: transform 0.3s, background-color 0.3s;
}
.text-content {
    text-indent: 2em;
}
.circle.active {
    transform: scale(1.2); /* 点击时放大 */
}
.core-principles-container .circle:nth-child(1) {
    background-color: #f7c6c3; 
}

.core-principles-container .circle:nth-child(2) {
    background-color: #edd4d9; 
}

.core-principles-container .circle:nth-child(3) {
    background-color: #c2e5ea; 
}

.core-principles-container .circle:nth-child(4) {
    background-color: #bcdbca; 
}
/* 小标题样式 */
.subtitles-container {
    display: block; /* 改为 block 使子项不居中 */
    width: 100%;
}

.subtitle {
    cursor: pointer;
    font-size: 1.5vw;
    font-weight: bold;
    color: #b998ae;
    transition: color 0.3s;
    text-align: left; /* 修改为左对齐 */
    margin-left: 0%; /* 可根据需要调整左边距 */
}

.subtitle:hover {
    color: #dda0ce; /* 悬停时更改颜色 */
}

.text-content {
    text-indent: 2em;
}
</style>
