<script>
export default {
  name:'CarouselComponent',
  data(){
    return{
    }
  },
  props:{
    height:{
      type:String,
      default:'30vw'
    },
    type:{
      type:String,
      default:undefined
    },
    arrow:{
      type:String,
      default:'always'
    },
    interval:{
      type:Number,
      default:5000
    },
    indicatorPosition:{
      type:String,
      default:undefined
    },
    loop: {
      type: Boolean,
      default: true
    },
    imageLinks:{
      type:Array
    }
  }
}
</script>

<template>

    <el-carousel :arrow="arrow"
                 :height="height"
                 :indicator-position="indicatorPosition"
                 :interval="5000"
                 :loop="loop"
                 :type="type"
    >

      <el-carousel-item v-for="link in imageLinks" :key="link.id" class="item">
        <router-link :to="{path:link.link}">
          <el-image :src="link.image"
                    class="image"
                    fit="cover">
            <div slot="error" class="image-slot" style="
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f5f7fa;
            ">
              <i class="el-icon-picture-outline">
              </i>
            </div>
          </el-image>
        </router-link>
      </el-carousel-item>
  </el-carousel>
</template>

<style scoped>
.image{
  width: 100%;
  height: 100%;
}

.el-icon-picture-outline{
  font-size: 70px;
  color: #909399;
}

</style>
