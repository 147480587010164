<template>
    <div :style="{width: this.width}" class="language-switcher"
         @mouseleave="showOptions = false"
         @mouseover="showOptions = true"
    >
        <div
                class="current-language"

        >
            {{ currentLanguage }}

            <transition name="el-fade-in">
                <ul v-show="showOptions" class="language-options">
                    <li
                            v-for="(lang, index) in languages"
                            :key="index"
                            @click="switchLanguage(lang)"
                    >
                        {{ lang }}
                    </li>
                </ul>
            </transition>
        </div>
        <span
                :class="{ rotated: showOptions }"
                class="triangle"
        ></span>
    </div>


</template>

<script>
export default {
    name: 'LanSwitcher',
    data() {
        return {
            currentLanguage: 'English', // 当前语言
            showOptions: false,         // 控制选项显示
            languages: ['English', '中文'], // 语言列表
        };
    },
    methods: {
        switchLanguage(lang) {
            this.currentLanguage = lang;
            this.$emit('change-lang', this.currentLanguage)
            // 这里可以添加切换语言的具体逻辑
        },

        updateLanguage() {
            const lang = localStorage.getItem('lang');
            if (lang === 'zh') {
                this.currentLanguage = '中文';
            } else if (lang === 'en') {
                this.currentLanguage = 'English'
            }
        },
    },
    props: {
        width: {
            type: String,
            default: '80px',
        }
    },
    mounted() {
        this.updateLanguage();
    }
};
</script>

<style scoped>
.language-switcher {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
}

.current-language {
    display: inline-block;
    color: #333333;
}


.triangle {
    display: inline-block;
    position: absolute;
    right: 5px;
    margin-left: 5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333; /* 三角形颜色 */
    transition: all 0.3s ease; /* 旋转动画 */
}

.rotated {
    transform: rotate(180deg); /* 旋转效果 */
}


.language-options {
    padding: 10px 10px;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #ffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    transition: all ease-in-out 0.3s;
    border-radius: 10px;
    margin: 0 0;
}

.language-options li {
    width: 150px;
    padding: 15px 10px;
    cursor: pointer;
    border-radius: 10px;
}

.language-options li:hover {
    background-color: #f5f5f5;
}
</style>
