import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import tools from "@/mixins/tools"
import 'element-ui/lib/theme-chalk/index.css';
import VueI18n from "vue-i18n";
import axios from 'axios';
import store from "@/store";

Vue.prototype.$axios = axios

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.mixin(tools)
Vue.use(VueI18n); // 全局挂载

// 创建i18n实例时，确保从localStorage获取的语言是有效的
const savedLocale = localStorage.getItem("lang") || "en";

export const i18n = new VueI18n({
    locale: savedLocale, // 确保locale正确设置
    messages: {
        zh: require("./lan/zh"), // 中文语言包
        en: require("./lan/en") // 英文语言包
    }
});

// 添加一个监听器，确保语言切换时内容立即更新
i18n.locale = savedLocale;

new Vue({
    router,
    i18n, // 使用国际化
    render: h => h(App),
    store,
    mounted() {
        // 在应用挂载之后，强制检查并更新当前语言的翻译状态
        this.$nextTick(() => {
            this.$i18n.locale = savedLocale;
        });
    }
}).$mount('#app')
