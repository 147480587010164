export const h = {
    //头部
    header: {
        home: '首页',
        projectIntro: '项目介绍',
        newsCenter: '新闻中心',
        aboutUs: '关于我们',
        joinUs: '加入我们',
        placeholder: '搜索',
        ourteam: '我们的团队',
    },
    //尾部
    footer: {
        cncDescription: 'CNC发展与支持是一个非营利组织，旨在帮助尼日利亚的盲人和视障儿童。',
        siteAnnouncements: '网站公告',
        projectUpdates: '项目动态', 
        videoUpdates: '视频动态',
        contactUs: '联系我们',
        phone: '电话：400-800-8888',
        email: '邮箱：aronkaibai@cncds.org', // 同样，这里你可能需要一个具体的邮箱地址 ,
        projectIntroduction: '项目介绍', // 新增  
        newsCenter: '新闻中心', // 新增  
        aboutUs: '关于我们', // 新增  
        joinUs: '加入我们', // 新增
        copyright: '版权所有 © 2024 CNC Development & Support', // 新增版权信息
        ourteam: '我们的团队'
    },
    //首页
    Home: {
        //新闻中心
        readMore: '点击阅读更多',
        newsCenter: '新闻中心',
        projectDynamics: '项目动态',
        //照片库
        photoGallery: '照片库',

        //我们的成就  	
        ourAchievements: {
            ourAchievements: '我们的成就',
            togetherForABrighterFuture: '携手共筑光明未来，每一份爱心都闪耀着希望的光芒',
            educationSupport: '教育资源助力',
            blindChildrenAchievements: '我们自豪地宣布，已成功帮助超过一百位盲人和视障儿童跨越障碍，获得宝贵的教育资源，为他们点亮知识的灯塔，开启无限可能。',
            donations: {
                title: '生活与学习物资捐赠',
                details: [
                    '慷慨捐赠包括200套大写字板、120支盲杖、40,000张盲文纸、120个盲人计算器等学习工具。',
                    '超过1000公斤的生活必需品，如米面粮油和奶粉，全方位支持盲童及家庭。']
            },
            campusUpgrades: '校园设施升级与学生关怀',
            campusUpgradesDetails: [
                '我们注重学习与生活的双重提升，为学生宿舍更换了更舒适的寝具并捐赠了风扇。',
                '同时，为校园音乐爱好者提供了丰富的乐器资源，如笛子和小号。'],
            communityActivities: '社区活动与健康教育',
            communityActivitiesDetails: [
                '与当地非政府组织合作举办了促进社区融合的活动。',
                '为学生举办了关于青少年健康与保健的夏季讲座。']
        }
    },

    //新闻中心
    newsCenterProjectDynamics: {
        newsCenterProjectDynamics: '新闻中心 - 项目动态',
        readMore: '点击阅读更多',
        previousPage: '上一页',
        nextPage: '下一页',
        newsDetails: '新闻详情',
        backToNewsCenter: '返回新闻中心',
        NotFound:'未找到对应的新闻详情。'
    },


    joinUs: {
        contactUs: '联系我们',
        name: '姓名',
        nameCannotBeEmpty: '姓名不能为空',
        phoneNumber: '手机号码',
        phoneNumberFormatIncorrect: '手机号码格式不正确',
        email: '邮箱',
        emailFormatIncorrect: '邮箱格式不正确',
        message: '留言',
        submit: '提交',
        aboutCNC: 'CNC Development & Support是一家位于尼日利亚的非政府组织，致力于帮助盲校和视障儿童获得更好的教育、学习和生活环境。我们的目标是通过提供高质量的教育资源和支持服务，帮助盲人和视障儿童实现自立，并且能够在未来更好地融入社会。我们致力于缩小教育差距，确保每一个盲人和视障儿童都能够获得公平的教育机会。',
        viewMore: '点击查看更多',
        cncOrganization: 'CNC Development & Support',
        address: '地址',
        emailInfo: '邮箱:aronkaibai@cncds.org',
        phoneNumberInfo: '电话号码'
    },

    //关于我们
    aboutUs: '关于我们',
    aboutUsContent: {
        p1: 'CNC Development & Support，自2023年9月25日在尼日利亚成立以来，便秉持着为盲校及视障儿童创造更好教育、学习和生活环境的初心与使命。我们深知，每一个孩子都有追求知识、享受生活的权利，因此，我们致力于成为他们前行路上的坚实后盾。',
        p2: '目前，CNC Development & Support取得了令人瞩目的成绩。我们成功助力超过一百位盲人和视障儿童获得宝贵的教育资源，为他们开启了知识的大门。在物资援助方面，我们不仅提供了200套写字板以提升孩子们的书写能力，还分发了120支盲杖确保他们的出行安全，捐赠了40,000张盲文纸作为充足的学习材料，并配备了120个盲人计算器助力数学学习。此外，我们还引入了盲人魔方、盲人扑克牌等游戏物资，丰富了他们的课余生活，并捐赠了超过1000公斤的生活物资以保障基本生活需求。设施方面，我们为学生宿舍更换了全新床上用品并捐赠风扇，确保他们在夏日也能享受舒适环境。艺术与文化上，我们捐赠了丰富的乐器，激发了孩子们对音乐的兴趣。社区活动中，我们与当地NGO紧密合作，成功举办跳蚤市场、游园会等活动，增进了社区交流并提升了公众对视障群体的关注。同时，我们还特别关注健康教育，暑假期间为学生们举办了生理卫生讲座，帮助他们建立正确的健康观念，学会自我保护。这些举措共同彰显了CNC Development & Support在改善盲人和视障儿童生活与教育条件方面的不懈努力与显著成效。',
        p3: 'CNC Development & Support的每一项成就都离不开广大援助者的慷慨解囊与无私奉献。您们的支持不仅为盲校和视障儿童带来了实实在在的帮助，更激发了社会各界对公益事业的关注与参与。我们坚信，通过共同努力，我们能够改变更多孩子的命运，为他们创造一个更加美好的未来。为了持续推动我们的公益事业，CNC Development & Support诚邀更多爱心人士加入我们，共同为盲校和视障儿童贡献力量。您的每一份捐赠都将化作他们成长路上的阳光雨露，助力他们茁壮成长。请访问我们的官方网站或联系我们，了解更多捐赠方式及详情。让我们携手并进，为爱传递温暖与希望！'
    },
    ourMission: '我们的任务',
    ourMissionContent: {
        section1: {
            title: '一、教育资源供给',
            content: {
                p1: '基础教育普及：我们首要的任务是为盲校及视障儿童提供全面的基础教育资源，包括定制化的教材、学习工具和辅助技术，确保每个孩子都能获得平等的学习机会。',
                p2: '特殊教育支持：鉴于盲童和视障儿童在学习上的特殊需求，我们引进并培训专业的特殊教育教师，采用创新的教学方法，帮助他们克服学习障碍，充分发挥潜能。',
                p3: '学习材料捐赠：我们已成功捐赠了包括盲文纸、盲人计算器、写字板等在内的学习材料，确保孩子们有充足的学习资源，为他们开启知识的大门。'
            }
        },
        section2: {
            title: '二、生活质量提升',
            content: {
                p1: '生活物资援助：除了教育资源，我们还关注孩子们的基本生活需求，捐赠生活物资如食品、衣物、床上用品等，确保他们能够在健康舒适的环境中成长。',
                p2: '健康教育与保护：我们重视盲童和视障儿童的健康教育，定期举办生理卫生讲座，教授他们自我保护的知识和技能，帮助他们建立正确的健康观念。',
                p3: '社区融入与参与：我们与当地社区紧密合作，组织各类活动如跳蚤市场、游园会等，增进社区交流，提升公众对视障群体的理解和关注，帮助孩子们更好地融入社会。'
            }
        },
        section3: {
            title: '三、心理与社会支持',
            content: {
                p1: '心理辅导与关怀：我们认识到心理健康对盲童和视障儿童的重要性，提供心理咨询服务，帮助他们建立自信，积极面对生活中的挑战。',
                p2: '兴趣与才艺培养：我们鼓励孩子们发展个人兴趣与才艺，捐赠乐器、盲人魔方等游戏物资，丰富他们的课余生活，培养多元化的技能。',
                p3: '社会倡导与宣传：通过我们的努力，提高社会对盲人和视障儿童群体的认知度，倡导包容与尊重，为他们争取更多的社会资源和支持。'
            }
        },
        start: 'CNC Development & Support自2023年9月25日在尼日利亚成立以来，便肩负着一项神圣而艰巨的任务——为当地盲童提供全方位的教育资源，并致力于提升他们的生活质量。我们深知，教育的力量能够照亮希望之路，为这些特殊的孩子开启无限可能。',
        closing: 'CNC Development & Support深知任务的艰巨与光荣，我们将继续秉持初心与使命，为当地盲童提供更加全面、深入的支持与帮助。我们相信，通过不懈的努力与社会的共同参与，我们能够改变更多孩子的命运，为他们创造一个更加美好的未来。'
    },
    ourStory: '我们的故事',
    ourStoryContent: {
        p1: '自2023年9月25日CNC Development & Support在尼日利亚成立以来，便肩负着一项神圣而艰巨的任务——为当地盲童提供全方位的教育资源，并致力于提升他们的生活质量。我们深知，教育的力量能够照亮希望之路，为这些特殊的孩子开启无限可能。CNC Development & Support的故事，源自于一群满怀热忱与梦想的热心人士，他们在一个充满希望的年份——让我们追溯到其萌芽之初的某个温暖春天，共同决定携手踏上一段非凡的旅程。这一年，不仅标志着CNC的诞生，更预示着一个关于爱、希望与改变的新篇章的开启。',
        p2: '起初，这群来自不同背景却拥有共同信念的人们聚集在一起，目睹了盲人和视障儿童在获取教育、融入社会及享受基本生活方面所面临的巨大挑战。他们深知，尽管世界在飞速发展，但这些孩子们往往被遗忘在角落，他们的声音和需求亟待被听见和满足。于是，CNC Development & Support应运而生，带着一个清晰而坚定的愿景：为盲人和视障儿童创造一个无障碍、充满机遇与爱的环境，让他们能够像所有人一样，自由地追求知识，享受生活的美好。'
    },
    corePrinciples: '我们的核心原则',
    corePrinciplesContent: {
        equality: '平等',
        respect: '尊重',
        education: '教育',
        care: '关爱'
    },

    //项目介绍
    DevSupport: ' CNC Development & Support ',
    projectOverview: '项目简述',
    projectOverviewContent: {
        p1: '尼日利亚的盲人和视障儿童在教育方面面临诸多挑战。由于资源匮乏，许多盲校缺乏必要的教学设备和教材，现有的盲人用品大部分损坏，导致这些孩子无法接受到与普通学生同等质量的教育。传统的教学方法对他们来说并不适用，而盲文书籍和盲文打印机等专用设备又极其稀缺。此外，社会对盲人和视障儿童的关注度较低，使得他们在接受教育和融入社会方面遇到重重障碍。',
        p2: 'CNC Development & Support 的目标是通过提供高质量的教育资源和支持服务,帮助盲人和视障儿童实现自立，并且能够在未来更好地融入社会。我们致力于缩小教育差距,确保每一个盲人和视障儿童都能够获得公平的教育机会。具体目标包括：为盲校提供盲文打印机、盲文书籍和其他专用教学设备；为教师提供专业培训，使他们能够更有效地教授盲人和视障学生；组织社区活动，提高公众对盲人和视障儿童教育问题的认识；与当地政府和NGO探讨为盲人/视力受损学生提供更多合适的就业培训。'
    },
    projectMilestones: '项目里程碑',
    projectMilestonesContent: {
        p1: '目前,CNC Development & Support取得了令人瞩目的成绩。我们成功助力超过一百位盲人和视障儿童获得宝贵的教育资源,为他们开启了知识的大门。在物资援助方面,我们不仅提供了200套写字板以提升孩子们的书写能力，还分发了120支盲杖确保他们的出行安全，捐赠了40,000张盲文纸作为充足的学习材料，并配备了120个盲人计算器助力数学学习。此外，我们还引入了盲人魔方、盲人扑克牌等游戏物资，丰富了他们的课余生活，并捐赠了超过1000公斤的生活物资以保障基本生活需求。设施方面，我们为学生宿舍更换了全新床上用品并捐赠风扇，确保他们在夏日也能享受舒适环境。艺术与文化上，我们捐赠了丰富的乐器，激发了孩子们对音乐的兴趣。',
        p2: '社区活动中,我们与当地NGO紧密合作,成功举办跳蚤市场、游园会等活动,增进了社区交流并提升了公众对视障群体的关注。同时,我们还特别关注健康教育,暑假期间为学生们举办了生理卫生讲座,帮助他们建立正确的健康观念，学会自我保护。这些举措共同彰显了CNC Development & Support在改善盲人和视障儿童生活与教育条件方面的不懈努力与显著成效。',
        p3: 'CNC Development & Support的每一项成就都离不开广大援助者的慷慨解囊与无私奉献。您们的支持不仅为盲校和视障儿童带来了实实在在的帮助,更激发了社会各界对公益事业的关注与参与。我们坚信,通过共同努力，我们能够改变更多孩子的命运，为他们创造一个更加美好的未来。为了持续推动我们的公益事业，CNC Development & Support诚邀更多爱心人士加入我们，共同为盲校和视障儿童贡献力量。您的每一份捐赠都将化作他们成长路上的阳光雨露，助力他们茁壮成长。请访问我们的官方网站或联系我们，了解更多捐赠方式及详情。让我们携手并进，为爱传递温暖与希望！'
    },
    expectedOutcomes: '预期成果',
    expectedOutcomesContent: {
        expectedOutcome1: '提高盲人和视障儿童的教育水平，使他们能够获得与普通学生同等的教育机会。',
        expectedOutcome2: '增强盲人和视障儿童的生活技能，使他们能够更好地适应社会生活。',
        expectedOutcome3: '提升社会对盲人和视障儿童教育问题的关注度，推动社会各界对盲人教育事业的支持。',
        expectedOutcome4: '通过教师培训，提高盲校教师的教学质量，进而提高学生的学习效果。'
    },
    DevSupport2: ' CNC Development & Support ',

    ourteamContent: {
        p1: 'President: Lijun Li',
        p2: 'Vice president: Yi Li, Junhong Li',
        p3: 'Member: Guanghui Xu, Jessica Chen'
    },
}


