<template>
    <div class="home">
        欢迎回来！
    </div>
</template>

<script>
export default {
    name: "BackendHomeViews"
}
</script>

<style scoped>
.home {
    display: flex;
    height: 70vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: #333;
}
</style>