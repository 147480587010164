<template>
    <div v-loading="isLoading">
        <div class="header">
            <router-link class="back-button" to="/XinWen">{{
                    $t("h.newsCenterProjectDynamics.backToNewsCenter")
                }}
            </router-link>
            <h1>{{ $t("h.newsCenterProjectDynamics.newsDetails") }}</h1>
        </div>

        <div v-if="newsItem" class="news-detail ">
            <div class="content">
                <h1>{{ newsItem.title }}</h1>
                <div class="news-content" v-html="newsItem.content"></div>
            </div>
        </div>
        <div v-else class="none">
            <p>未找到对应的新闻详情。</p>
        </div>

        <!-- 分页按钮 -->
        <div class="pagination-container">
            <div class="pagination">
                <el-button :disabled="nextDisable" round @click.prevent.native="prevPage"><i
                    class="el-icon-caret-left"/>
                    {{ $t("h.newsCenterProjectDynamics.previousPage") }}
                </el-button>
                <el-button :disabled="preDisable" round @click.prevent.native="nextPage">
                    {{ $t("h.newsCenterProjectDynamics.nextPage") }}<i class="el-icon-caret-right"/></el-button>

                <!--                <a :class="{ 'disabled': currentPage === 1 }" href="#" @click.prevent="prevPage">
                                    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 14L5 8l6-6"/>
                                    </svg>
                                    {{ $t("h.newsCenterProjectDynamics.previousPage") }}
                                </a>
                                <a :class="{ 'disabled': currentPage * itemsPerPage >= this.newItems.length }" href="#"
                                   @click.prevent="nextPage">
                                    {{ $t("h.newsCenterProjectDynamics.nextPage") }}
                                    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 14l6-6-6-6"/>
                                    </svg>
                                </a>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewsDetail',
    data() {
        return {
            newItems: null,
            newsItem: null, // 用于存储当前显示的新闻详情
            currentPage: null,
            preDisable: null,
            nextDisable: null,
            isLoading: true,
        };
    },
    mounted() {
        this.loadNewsDetail();

    },
    methods: {
        loadNewsDetail() {
            // 获取路由参数 id
            this.currentPage = parseInt(this.$route.params.id);

            this.getArticles();
            this.getArticle(this.currentPage);
        },
        getArticles() {
            this.$axios({
                method: 'get',
                url: 'api/common/article/list',
                // url: 'https://apifoxmock.com/m1/5093316-4755738-default/common/article/list',
                headers: {
                    'Content-Type': "application/json;charset=UTF-8"
                },
            }).then(res => {
                this.isLoading = false;
                if (res.data.code === 1) {
                    this.newsItems = res.data.data;
                    this.handleDisabled();
                } else {
                    console.log(res.data.message);
                }
            }).catch(err => {
                this.isLoading = false;
                this.$message({
                    type: 'error',
                    message: err.message,
                })
            })
        },
        getArticle(id) {
            this.$axios({
                method: 'get',
                url: `/api/common/article/${id}`,
                headers: {
                    'Content-Type': "application/json;charset=UTF-8"
                },
            }).then(res => {
                if (res.data.code === 1) {
                    this.newsItem = res.data.data;
                } else {
                    console.log(res.data.message);
                }
            })
        },
        handleDisabled() {
            if (!this.newsItems || this.newsItems.length === 0) {
                this.preDisable = true;
                this.nextDisable = true;
                return;
            }
            // 获取当前文章在 newsItems 中的位置
            const currentIndex = this.newsItems.findIndex(item => item.id === this.currentPage);

            // 如果找不到当前文章，禁用按钮
            if (currentIndex === -1) {
                this.preDisable = true;
                this.nextDisable = true;
                return;
            }
            // 判断是否有前一篇和后一篇
            this.preDisable = currentIndex === 0;
            this.nextDisable = currentIndex === this.newsItems.length - 1;
        },
        prevPage() {
            const currentIndex = this.newsItems.findIndex(item => item.id === this.currentPage);
            if (currentIndex < this.newsItems.length - 1) {
                const nextArticle = this.newsItems[currentIndex + 1];
                this.$router.push({name: 'NewsDetail', params: {id: nextArticle.id}});
            }
        },
        nextPage() {
            const currentIndex = this.newsItems.findIndex(item => item.id === this.currentPage);
            if (currentIndex > 0) {
                const prevArticle = this.newsItems[currentIndex - 1];
                this.$router.push({name: 'NewsDetail', params: {id: prevArticle.id}});
            }
        },
    },
    watch: {
        '$route.params.id': 'loadNewsDetail',
    }
};
</script>


<style scoped>
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
}

.header {
    position: relative;
    max-width: 79vw;
    margin: 2vw auto;
    padding: 1vw 2vw;
    background-color: rgba(255, 255, 255, 0);
}

.header h1 {
    text-align: center;
    font-size: 2vw;
}


.header .back-button {
    position: absolute;
    top: 0.5vw;
    left: 2vw;
    padding: 0.8vw 2vw;
    background-color: #cccccc;
    color: #fff;
    text-decoration: none;
    border-radius: 0.5vw;
    transition: background-color 0.3s ease;
}

.header .back-button:hover {
    background-color: #92a2b4;
}

.news-detail {
    background-color: #ffffff;
    border-radius: 1vw;
    box-shadow: 0 0.2vw 0.4vw rgba(0, 0, 0, 0.1);
    padding: 2vw;
    position: relative;
    overflow: hidden;
    margin-bottom: 2vw;
    max-width: 75vw;
    margin-left: auto;
    margin-right: auto;
}

.news-detail h1 {
    font-size: 2vw;
    margin-bottom: 2vw;
}

.news-detail p {
    color: #666666;
    line-height: 1.6;
    margin-bottom: 2vw;
}

.news-detail img {
    max-width: 100%;
    border-radius: 1vw;
    margin: 2vw 0;
}

.news-detail .content {
    position: relative;
}

.news-detail .content img {
    margin-bottom: 0;
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -3vw;
    max-width: 75vw;
    margin-left: auto;
    margin-right: auto;
    height: 9vw;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #f8f9fa;
    padding: 0.7vw;
    border-radius: 1vw;
    box-shadow: 0 0.2vw 0.4vw rgba(0, 0, 0, 0.1);
}

.pagination button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 0.7vw 3vw;
    background-color: #c4ccd4;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.pagination button:hover {
    background-color: #92a2b4;
}

.pagination button.disabled {
    background-color: #cccccc;
    pointer-events: none;
}

.pagination a svg {
    margin-right: 0.5vw;
    width: 1.5vw;
    height: 1.5vw;
    fill: currentColor;
}

.pagination a:first-child {
    margin-right: -0.5vw;
}

.pagination a:last-child {
    margin-left: -0.5vw;
}

/* 新增 */
.news-content {
    text-indent: 2em;
    /* 使用em单位，相对于当前字体的尺寸来缩进 */
}

.none {
    text-align: center;
    height: 100px;
}
</style>
