<script>
import FooterComponents from "@/components/index/Footer.vue";
import HeaderComponent from "@/components/index/Header.vue";

export default {
    name: 'HomeView',
    components: {HeaderComponent, FooterComponents}

}
</script>
<template>
  <div class="home">
    <HeaderComponent></HeaderComponent>
    <router-view></router-view>
    <FooterComponents></FooterComponents>
  </div>
</template>


<style scoped>
.home {
  background-color: rgba(250, 251, 253, .1);
}
</style>