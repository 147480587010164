<template>
    <div class="container">
        <div class="main-box">
            <div class="title-box">新闻中心 - 视频动态</div>
            <div class="news-container" ref="newsContainer">
                <div v-for="(video, index) in paginatedVideos" :key="index" class="news-item">
                    <h3 class="news-title">{{ video.title }}</h3>
                    <hr class="news-item-separator">
                    <video controls width="320" height="240">
                        <source :src="video.src" type="video/mp4">
                        您的浏览器不支持 HTML5 视频。
                    </video>
                    <p class="news-description">{{ video.description }}</p>
                    <div class="news-read-more-box">
                        <router-link 
                            :to="{ name: 'VideoDetail', params: { id: video.id }}" 
                            class="news-read-more"
                        >
                            点击查看更多
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="pagination-container">
            <div 
                class="pagination-box pagination-prev" 
                @click="prevPage"
                :class="{ disabled: currentPage === 1 }"
            >
                上一页
            </div>
            <div class="pagination-numbers">
                <div 
                    v-for="page in paginationPages"
                    :key="page"
                    class="pagination-number"
                    :class="{ active: page === currentPage, ellipsis: page === ellipsisPage }"
                    @click="goToPage(page)"
                >
                    {{ page === ellipsisPage ? '...' : page }}
                </div>
            </div>
            <div 
                class="pagination-box pagination-next" 
                @click="nextPage"
                :class="{ disabled: currentPage === totalPages }"
            >
                下一页
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShiPin',
    data() {
        return {
            totalVideos: 8, // 视频项目的总数
            itemsPerPage: 6, // 每页显示的视频项目数量
            currentPage: 1, // 当前页码
            videos: [
                { id: 1, title: '视频 1', src: 'shipin/1.mp4', description: '这是视频动态的简短描述。' },
                { id: 2, title: '视频 2', src: 'shipin/1.mp4', description: '这是视频动态的简短描述。' },
                { id: 3, title: '视频 3', src: 'shipin/1.mp4', description: '这是视频动态的简短描述。' },
                { id: 4, title: '视频 4', src: 'shipin/1.mp4', description: '这是视频动态的简短描述。' },
                { id: 5, title: '视频 5', src: 'shipin/1.mp4', description: '这是视频动态的简短描述。' },
                { id: 6, title: '视频 6', src: 'shipin/1.mp4', description: '这是视频动态的简短描述。' },
                { id: 7, title: '视频 7', src: 'shipin/1.mp4', description: '这是视频动态的简短描述。' },
                { id: 8, title: '视频 8', src: 'shipin/1.mp4', description: '这是视频动态的简短描述。' }
            ]
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalVideos / this.itemsPerPage);
        },
        paginatedVideos() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.videos.slice(start, end);
        },
        paginationPages() {
            const pages = [];
            for (let i = 1; i <= this.totalPages; i++) {
                pages.push(i);
            }
            return pages;
        },
        ellipsisPage() {
            return this.currentPage > 3 ? 3 : null;
        }
    },
    methods: {
        goToPage(page) {
            if (page !== this.currentPage && page > 0 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.goToPage(this.currentPage - 1);
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.goToPage(this.currentPage + 1);
            }
        }
    },
    mounted() {
        this.goToPage(this.currentPage);
    }
};
</script>

<style scoped>
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
}

.container {
    max-width: 80%;
    margin: 0 auto;
    padding: 2vw;
}

.main-box {
    position: relative;
    background-color: #f0f0f0;
    padding: 1.7vw; 
    border: 0.1vw solid #ddd; 
}

.title-box {
    position: absolute;
    top: 2vw; 
    left: 1.7vw; 
    background-color: #ffffff;
    padding: 0.7vw 1vw; 
    font-size: 1.1vw; 
    font-weight: bold;
    color: #333;
    border: 0.1vw solid #ddd; 
}

.news-container {
    margin-top: 4vw; 
    display: flex;
    flex-wrap: wrap;
    gap: 2vw; 
    border: 0.1vw solid #ddd; 
    padding: 1.3vw; 
    background-color: #ffffff;
}


.news-item {
    background-color: #f0f0f0;
    padding: 2vw; 
    transition: transform 0.2s, box-shadow 0.2s;
    position: relative;
    overflow: hidden;
    flex: 1 1 calc(50% - 2vw); 
    min-height: 20vw; 
    border: 0.1vw solid #ddd; 
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.news-item:hover {
    transform: translateY(-0.1vw); 
    box-shadow: 0 0.4vw 1.2vw rgba(0, 0, 0, 0.2); 
}

.news-title {
    font-size: 1.2vw;
    font-weight: bold;
    text-align: center;
}

.news-description {
    font-size: 0.9vw; 
    text-align: center;
    margin-top: 0;
    margin-bottom: 1.5vw; 
    line-height: 1.5;
}


.news-item-separator {
    width: 80%;
    margin: 1vw auto; 
    border-top: 0.1vw solid #d4d2d2; 
}


.news-read-more-box {
    position: absolute;
    bottom: 1vw; 
    left: 1vw; 
    background-color: rgba(251, 200, 149, 0.9);
    padding: 0.5vw 1vw; 
    z-index: 2;
    cursor: pointer;
}

.news-read-more {
    font-size: 0.8vw;
    margin: 0;
    color: #000;
    text-decoration: none;
}

.pagination-container {
    width: 100%;
    margin: 2vw 0; 
    background-color: #f0f0f0;
    border: 0.1vw solid #ddd; 
    padding: 1vw; 
    text-align: center;
}

.pagination-box {
    display: inline-block;
    margin: 0 1vw;
    padding: 0.5vw 1vw;
    background-color: #ffffff;
    border: 0.1vw solid #ddd; 
    cursor: pointer;
    font-size: 1vw;
    color: #333;
}

.pagination-box:hover {
    background-color: #ddd;
}

.pagination-box.disabled {
    cursor: not-allowed;
    color: #ccc;
}

.pagination-numbers {
    display: inline-block;
    margin: 0 1vw;
}

.pagination-number {
    display: inline-block;
    width: 2vw;
    height: 2vw;
    line-height: 2vw;
    text-align: center;
    background-color: #ffffff;
    border: 0.1vw solid #ddd; 
    margin: 0;
    cursor: pointer;
    font-size: 1vw;
    color: #333;
}

.pagination-number:hover {
    background-color: #ddd;
}

.pagination-number.active {
    background-color: #333;
    color: #fff;
}

@media (max-width: 768px) {
    .news-item {
        flex: 1 1 100%;
    }
}

@media (max-width: 480px) {
    .header h1 {
        font-size: 4vw;
    }

    .news-title {
        font-size: 2vw;
    }

    .news-description,
    .news-read-more {
        font-size: 1.5vw;
    }

    .pagination-box,
    .pagination-number {
        padding: 0.8vw;
        font-size: 1.5vw;
    }
}
</style>
