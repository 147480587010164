<script>
export default {
  name: "PhotoSlider",
  data() {
    return {
      processedPreviewList: [],
      currentIndex: 0,
      isAnimating: false,  //动画进行中的标志
      itemsPerView: 4,  //默认显示4张图片
    };
  },
  props: {
    images: {
      type: Array,
    },
    previewList: {
      type: Array,
      default: undefined,
    },
    title: {
      type: String,
    },
    height: {
      type: String,
      default: '220px',
    },
  },
  computed: {
    itemStyle() {
      return {
        height: this.height,
        minWidth: `${100 / this.itemsPerView}%` /* 一行显示 4 张图片 */
      };
    },
    trackStyle() {
      return {
        transform: `translateX(${this.currentIndex * (100 / this.itemsPerView)}%)`,
      }
    }
  },
  methods: {
    prev() {
      if (!this.isAnimating) {
        this.isAnimating = true;
        this.currentIndex = (this.currentIndex + 1 - (this.images.length - this.itemsPerView)) % (this.images.length - this.itemsPerView);
        setTimeout(() => {
          this.isAnimating = false;
        }, 300); // 与 CSS 动画时间一致
      }
    },

    next() {
      if (!this.isAnimating) {
        this.isAnimating = true;
        this.currentIndex = (this.currentIndex - 1) % (this.images.length - this.itemsPerView);
        setTimeout(() => {
          this.isAnimating = false;
        }, 300); // 与 CSS 动画时间一致
      }
    },
    processPreviewList() {
      this.processedPreviewList = this.previewList.map(item => item.image);
    },

    updateItemsPreView() {
      const width = window.innerWidth;
      if (width <= 767) {
        this.itemsPerView = 1;
      } else if (width <= 1024) {
        this.itemsPerView = 2;
      } else {
        this.itemsPerView = 4;
      }
    }
  },
  created() {
    this.processPreviewList();
  },
  mounted() {
    this.updateItemsPreView();
    window.addEventListener('resize', this.updateItemsPreView);
  },
  beforeDestroy() {
    this.updateItemsPreView();
    window.addEventListener('resize', this.updateItemsPreView);
  }
};

</script>

<template>
   <div class="photo-slider">
      <div class="title">{{ title }}</div>
      <div class="slider-box">
         <button class="arrow left" @click="prev">&lt;</button>
         <div class="slider-track-container">
            <div :style="trackStyle" class="slider-track">
               <div v-for="(image, index) in images" :key="index" :style="itemStyle" class="slider-item">
                  <el-image :preview-src-list="processedPreviewList"
                            :src="image.image"
                            class="image"
                            fit="cover">
                     <div slot="error" class="image-slot" style="
                           width: 300px;
                           height: 100%;
                           display: flex;
                           align-items: center;
                           justify-content: center;
                           background: #f5f7fa;
                           ">
                        <i class="el-icon-picture-outline">
                        </i>
                     </div>
                  </el-image>
               </div>
            </div>
         </div>
         <button class="arrow right" @click="next">></button>
      </div>
   </div>
</template>

<style scoped>
.photo-slider {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
}

.title {
    color: #2b2b2b;
    font-size: 30px;
    font-weight: bold;
    height: 50px;
    margin: 0 auto 20px;
}

.slider-box {
    position: relative;
}


.slider-track-container {
    display: flex;
    overflow: hidden;
    width: 100%;
}

.slider-track {
    display: flex;
    transition: transform 0.3s ease-in-out;
}

.slider-item {

    box-sizing: border-box;
    height: 220px;
    padding: 10px;
}

.image {
    display: block;
    height: 100%;
    object-position: center;
}

.arrow {
    background-color: rgba(31, 45, 61, .11);
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    font-size: 20px;
    height: 36px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
    width: 36px;
    z-index: 10;
}

.arrow:hover {
    background-color: rgba(31, 45, 61, .23);
}

.arrow.left {
    left: 15px;
}

.arrow.right {
    right: 15px;
}

</style>
